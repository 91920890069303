<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success3 opacity-6"></span>
    </div>

    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="https://token-monitor.s3.amazonaws.com/1672739254216screenshot-20230103-174724.png"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>

        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">抢预售工具</h5>
            <p class="mb-0 text-sm font-weight-bold">
              通过燃烧区块的方式参与预售
            </p>
            <p class="mb-0 text-sm font-weight-bold">
              <!-- ✔ 批量合约已开源 支持任何审计 -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col col-12 col-lg-8 col-md-8 col-sm-12 mt-2">
          <div class="row config-panel">
            <div class="col col-12 col-md-4 col-sm-12">
              <div class="config-panel">
                <div class="config-panel-item">
                  <label>抢购类型</label>
                  <div class="config-panel-item-body">
                    <select v-model="form.presaleType" class="form-control">
                      <option
                        v-for="item in [
                          {
                            id: 'pink',
                            name: '粉红预售',
                          },
                        ]"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="col col-12 col-md-8 col-sm-12">
              <ConfigPanel :display-exchange="false"> </ConfigPanel>
            </div>
          </div>
          <div class="row config-panel mt-4">
            <div class="col col-md-8 col-lg-8 col-sm-12">
              <label class="label-row"
                >输入预售合约
                <option-description
                  title="输入预售合约"
                  message="在对应的预售界面或者网址中可以找到,
                  请注意不要输入代币合约"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input v-model="form.contract" type="text" class="form-control" />
            </div>
            <div class="col col-md-4 col-lg-4 col-sm-12">
              <label class="label-row"
                >预售开始时间

                <option-description
                  title="预售开始时间"
                  message="可在预售详情页面找到, 请注意是是本地时间, 程序将会自动转换为UTC时间, 开始时将会在日志框中展示"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <flat-pickr
                v-model="form.time"
                class="form-control datetimepicker"
                placeholder="选择预售开始时间(UTC)"
                :config="config"
              ></flat-pickr>
            </div>
          </div>
          <div class="row config-panel">
            <div class="col col-md-3 col-lg-3 col-sm-6">
              <label class="form-label label-row"
                >gasLimit
                <option-description
                  title="gasLimit"
                  message="提交预售交易时使用的gasLimit, 该值越大, 预售钱包中需要准备的手续费就越多, 但这里只是最大消耗上限, 如果没有达到上限, 是不会消耗多余的手续费的"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input v-model="form.gasLimit" type="text" class="form-control" />
            </div>
            <div class="col col-md-3 col-lg-3 col-sm-6">
              <label class="label-row"
                >gasPrice
                <option-description
                  title="gasPrice"
                  message="提交预售交易时使用的gasPrice, 该值越大, 交易会越先被矿工打包, 需要消耗的手续费也就越多"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input v-model="form.gasPrice" type="text" class="form-control" />
            </div>
            <div class="col col-md-3 col-lg-3 col-sm-6">
              <label class="form-label label-row"
                >支付代币
                <option-description
                  title="支付代币"
                  message="参与预售时要使用的代币, 非公链代币需要提前进行授权"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <select v-model="form.payToken" class="form-control">
                <option
                  v-for="item in currencyList"
                  :key="item.address"
                  :value="item.address"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col col-md-3 col-lg-3 col-sm-6">
              <label class="label-row"
                >支付数量
                <option-description
                  title="支付数量"
                  message="要参与预售的金额, 注意请勿超过预售合约中限制的最小值和最大值, 否则会造成抢购失败"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input
                v-model="form.payAmount"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="row config-panel">
            <div class="col col-md-4 col-lg-4 col-sm-6">
              <label class="label-row"
                >提前开抢秒数
                <option-description
                  title="提前开抢秒数"
                  message="由于各个节点之间的信息同步存在延迟, 所以需要在预售交易前提前几个区块开始尝试买入, 尝试每一个区块买入一次, 直到交易成功"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input
                v-model="form.advanceSeconds"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col col-md-4 col-lg-4 col-sm-6">
              <label class="label-row"
                >请求间隔
                <option-description
                  title="请求间隔"
                  message="每一笔抢购交易的间隔,为了确保每一个区块都能接收到一笔抢购交易, 推荐将此处设置为出块间隔或更短一点的时间, 例如BSC是3s挖出一个区块, ETH是15s挖出一个区块"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input
                v-model="form.requestLimit"
                type="text"
                class="form-control"
              />
            </div>
            <div class="col col-md-4 col-lg-4 col-sm-6">
              <label class="label-row"
                >请求次数
                <option-description
                  title="请求次数"
                  message="从开始首次抢购请求后, 每隔指定时间, 提交的交易次数, 例如提前9s, 间隔为3s, 那么此处可以设置为4, 确保在预售开始前的区块, 都会有一笔抢购交易"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input
                v-model="form.requestCount"
                type="text"
                class="form-control"
              />
            </div>
          </div>
          <div class="row config-panel">
            <div class="col col-md-12 col-lg-12 col-sm-12">
              <label class="label-row"
                >自定义RPC
                <option-description
                  title="自定义RPC"
                  message="默认使用公共的RPC节点, 如果您购买了更快的专有节点, 可在此填写. 如果有条件的话, 可以使用专有的RPC节点, 然后购买一台和该节点相同地区的云主机, 在云主机上运行本程序, 可最大程度的降低延迟."
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </label>
              <input
                v-model="form.rpc"
                placeholder="(可选)"
                type="text"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="col col-12 col-md-4 col-lg-4 col-sm-12 mt-2">
          <div class="row">
            <div class="count-down-box">{{ countdownString }}</div>
          </div>
          <div class="row">
            <div class="col-12">
              <soft-button v-if="!submitLoading" full-width @click="submit">
                准备抢购
              </soft-button>
              <soft-button
                v-if="submitLoading"
                color="danger"
                full-width
                @click="stopWorkers"
              >
                <el-icon class="is-loading">
                  <loading />
                </el-icon>

                停止抢购
              </soft-button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <soft-alert color="info">
                <span style="font-size: 14px">
                  本程序会尝试进行多次提交预售交易,
                  请确保钱包中的代币余额仅足够支付成功1次您的交易,
                  这样其他重复交易将会失败, 防止会成交多笔交易
                </span>
              </soft-alert>
            </div>
          </div>
          <div class="panel mt-2 row">
            <label>日志</label>
            <soft-textarea
              :scroll-to-top="true"
              :value="logs"
              :rows="15"
              placeholder="操作日志"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfigPanel from "@/components/CommonConfig/ConfigPanel.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import flatPickr from "vue-flatpickr-component";
import OptionDescription from "./components/OptionDescription.vue";
import { currencyListMap, tokenAbi } from "@/utils/config";
import { ElIcon } from "element-plus";
import { InfoFilled, Loading } from "@element-plus/icons-vue";
import { WorkerPool } from "@/utils/worker_pool";
import { toRaw } from "vue";
import { BigNumber, ethers } from "ethers";
const maxApprove = BigNumber.from(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
);
export default {
  components: {
    ConfigPanel,
    SoftInput,
    SoftButton,
    OptionDescription,
    flatPickr,
    ElIcon,
    SoftTextarea,
    InfoFilled,
    Loading,
    SoftAlert,
  },
  data() {
    return {
      config: {
        allowInput: true,
        enableTime: true,
        // dateFormat: "U",
        dateFormat: "Y-m-d H:i:S",
      },
      logs: "",
      countdown: "",
      submitLoading: false,
      form: {
        presaleType: "pink",
        contract: "", // 预售合约
        time: "",
        gasLimit: "2000000",
        gasPrice: "6",
        payToken: "",
        payAmount: "",
        advanceSeconds: "9",
        requestLimit: "3",
        requestCount: "4",
        rpc: "",
      },
    };
  },
  computed: {
    countdownString() {
      if (this.countdown && parseInt(this.countdown) > 0) {
        const x = this.countdown;
        let ss = x % 60; // 秒数
        let mm = Math.floor((x / 60) % 60); // 分钟数
        let hh = Math.floor((x / 60 / 60) % 60); // 小时数
        let dd = Math.floor((x / 60 / 60 / 60) % 60); // 天数
        if (ss < 10) ss = "0" + ss + "";
        if (mm < 10) mm = "0" + mm + "";
        if (hh < 10) hh = "0" + hh + "";
        if (dd < 10) dd = "0" + dd + "";
        return dd + ":" + hh + ":" + mm + ":" + ss;
      }
      return "00:00:00:00";
    },
    payTokenInfo() {
      const ls = this.currencyList.filter((item) => {
        return item.address.toLowerCase() === this.form.payToken.toLowerCase();
      });
      if (ls.length > 0) {
        return ls[0];
      }
      return {};
    },
    currentNetwork() {
      return this.$store.state.network;
    },
    currencyList() {
      return currencyListMap[this.currentNetwork.chainId];
    },
    currencyAddress() {
      if (!this.currencyList || this.currencyList.length === 0) {
        return "";
      }
      const c = this.currencyList.filter((item) => {
        return (item.isWETH = true);
      });
      if (c.length > 0) {
        return c[0].address;
      }

      return "";
    },
    currencyName() {
      if (!this.currencyList || this.currencyList.length === 0) {
        return "";
      }
      const c = this.currencyList.filter((item) => {
        return (item.isWETH = true);
      });
      if (c.length > 0) {
        return c[0].name;
      }

      return "";
    },
  },
  methods: {
    initEnv() {
      return new Promise((resolve) => {
        this.initLoading = true;
        // 初始化环境
        window.goWorkers = new WorkerPool("./worker5.js", parseInt(1));
        window.goWorkers.init(
          // "./test.wasm",
          //   "https://token-monitor.s3.amazonaws.com/test_12_02_01_52.wasm",
          "https://token-monitor.s3.amazonaws.com/test_01_05_10_48.wasm",
          (nowLength, totalLength) => {
            const p = ((nowLength / totalLength) * 100).toFixed(2);
            this.logs = "正在初始化运行环境" + `(${p}%)`;
          },
          () => {
            resolve();
          }
        );
      });
    },
    log(str) {
      this.logs += "\n" + str;
      this.$nextTick(() => {
        console.log(this.logs);
      });
    },
    isAddress(address) {
      return address.toLowerCase().indexOf("0x") === 0 && address.length === 42;
    },
    async submit() {
      if (this.submitLoading) return;

      if (this.$store.state.loginNetwork.vipInfo.length === 0) {
        const r = await this.$swal({
          title: "请开通VIP",
          text: `当前功能为付费服务, 请先开通VIP`,
          showCancelButton: true,
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
        if (!r.isConfirmed) {
          return;
        } else {
          // 跳转到开通vip的界面
          this.$router.push({
            path: "/vip",
          });
          return;
        }
      }

      // todo 判断VIP
      if (!this.form.time) {
        this.$swal({
          title: "请选择预售时间",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (!this.currentNetwork.currentWallet) {
        this.$swal({
          title: "请选择钱包",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (!this.form.payToken) {
        this.$swal({
          title: "请选择支付代币",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.payAmount) {
        this.$swal({
          title: "请输入支付金额",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.advanceSeconds) {
        this.$swal({
          title: "请输入提前开抢秒数",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.isAddress(this.form.contract)) {
        this.$swal({
          title: "当前输入的预售合约不正确",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.requestLimit) {
        this.$swal({
          title: "请输入请求间隔",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.requestCount) {
        this.$swal({
          title: "请输入请求次数",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      this.submitLoading = true;
      // 开始加载wasm
      await this.initEnv();
      this.log("运行环境初始化完成");
      let provider = toRaw(this.currentNetwork.provider);
      if (this.form.rpc) {
        // 解析当前的rpc
        this.log("正在使用自定义节点...");
        provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
      }
      console.log(provider, 222);

      const rpc = provider.connection.url;
      this.log("正在判断节点连通性...");

      try {
        const delay = await window.goWorkers.pingRpc(rpc);
        this.log(`通过!  当前延迟为: ${delay} 毫秒`);
      } catch (e) {
        this.log("失败! 请检查节点或网络!");
        this.stopWorkers();
        return;
      }

      const wallet = this.currentNetwork.currentWallet;

      const balance = await provider.getBalance(wallet);
      this.log(
        `当前账号余额为:  ${balance.toString() / 1e18} ${this.currencyName}`
      );

      const fee = ethers.utils.parseUnits(
        (
          parseFloat(this.form.gasLimit) * parseFloat(this.form.gasPrice)
        ).toFixed(16) + "",
        9
      );

      this.log(
        `单次交易预计最多需要 ${this.form.gasLimit} * ${
          this.form.gasPrice
        } GWEI ≈ ${fee.toString() / 1e18} ${this.currencyName}`
      );
      const totalFee = fee.mul(
        BigNumber.from(parseInt(this.form.requestCount))
      );
      console.log(totalFee.toString());
      this.log(
        `${this.form.requestCount} 次交易共需要 ${totalFee.toString() / 1e18} ${
          this.currencyName
        }`
      );

      let isEth = false;
      // 判断是否是公链本币
      if (
        this.form.payToken.toLowerCase() === this.currencyAddress.toLowerCase()
      ) {
        isEth = true;
      }
      let need = totalFee;
      if (isEth) {
        need = totalFee.add(ethers.utils.parseEther(this.form.payAmount + ""));
      } else {
        // 判断其他的代币余额是否充足
        // this.payTokenInfo
        const t = new ethers.Contract(
          this.payTokenInfo.address,
          tokenAbi,
          provider
        );
        const b = await t.balanceOf(this.currentNetwork.currentWallet);
        console.log("余额是", b.toString() / 1e18);
        this.log(
          `当前 ${this.payTokenInfo.name} 余额为: ${b.toString() / 1e18}`
        );
        if (
          b.toString() / 1e18 <
          ethers.utils.parseEther(this.form.payAmount + "").toString() / 1e18
        ) {
          this.log(`${this.payTokenInfo.name} 余额不足, 请补充余额`);
          this.stopWorkers();
          return;
        }

        this.log(`正在检测授权情况...`);
        // 判断是否授权
        const ap = await t.allowance(
          this.currentNetwork.currentWallet,
          this.form.contract // 预售合约
        );
        if (parseInt(ap.toString()) < parseInt(maxApprove.toString()) * 0.9) {
          this.log(
            `预售地址 ${this.form.contract} 对代币 ${this.payTokenInfo.name} 未授权, 即将开始授权...`
          );
          let tFace = new ethers.utils.Interface(tokenAbi);
          const dd = tFace.encodeFunctionData("approve", [
            this.form.contract,
            maxApprove,
          ]);
          const w = new ethers.Wallet(this.currentNetwork.privateKey, provider);
          const approveTx = {
            from: this.currentNetwork.currentWallet,
            to: this.payTokenInfo.address,
            gasLimit: ethers.utils.parseUnits(this.form.gasLimit + "", 0),
            data: dd,
            value: ethers.utils.parseEther("0"),
          };
          const approveX = await w.sendTransaction(approveTx);
          this.log(`授权交易已提交, hash: ${approveX.hash} 等待交易完成...`);
          await approveX.wait();
          this.log("授权完成");
        }
      }
      // 判断余额是否充足
      if (need.toString() / 1e18 > balance.toString() / 1e18) {
        this.log(
          `账户内余额不足, 请在钱包中至少准备 ${need.toString() / 1e18} ${
            this.currencyName
          }`
        );
        this.stopWorkers();
        return;
      }

      // 授权完成后开始倒计时
      this.log(`倒计时开始...`);
      // const timestamp = Date.parse(new Date(this.form.time)) / 1000;
      const timestamp = Date.parse(new Date()) / 1000 + 30;
      window.goWorkers
        .startPresaleLogic(
          [
            this.form.presaleType,
            this.form.contract,
            timestamp,
            parseInt(this.form.gasLimit),
            ethers.utils.parseUnits(this.form.gasPrice + "", 9).toString(),
            this.form.payToken,
            isEth,
            ethers.utils.parseEther(this.form.payAmount + "").toString(),
            parseInt(this.form.advanceSeconds),
            parseInt(this.form.requestLimit),
            parseInt(this.form.requestCount),
            rpc,
            this.currentNetwork.privateKey,
            this.currentNetwork.chainId,
            this.currentNetwork.currentWallet,
          ],
          (cd) => {
            console.log("接受到倒计时信息", cd);
            this.countdown = cd;
          },
          (info) => {
            console.log("接受到信息", info, 111);
            const inf = JSON.parse(info);
            if (inf.type === "timeout") {
              this.log(`预售时间已过, 未能提前发送交易请求`);
            }
            if (inf.type === "start") {
              this.log(`倒计时完成, 开始尝试提交交易...`);
            }
            if (inf.type === "success") {
              this.log(
                `第 ${parseInt(inf.count) + 1} 次交易已提交, 交易哈希: ${
                  inf.res
                }`
              );
            }
            if (inf.type === "error") {
              this.log(
                `第 ${parseInt(inf.count) + 1} 次交易提交失败, 错误: ${
                  inf.err
                } , nonce: ${inf.nonce}`
              );
            }
            if (inf.type === "wait") {
              this.log("全部交易已提交, 等待交易完成...");
            }
            if (inf.type === "txError") {
              this.log(
                `第 ${parseInt(inf.count) + 1} 次交易执行失败, 交易哈希: ${
                  inf.hash
                }`
              );
            }
            if (inf.type === "txSuccess") {
              this.log(
                `第 ${parseInt(inf.count) + 1} 次交易执行成功, 交易哈希: ${
                  inf.hash
                }`
              );
            }
          }
        )
        .then(() => {
          console.log("操作成功");
          this.log(`全部预售操作已完成, 退出程序`);
          this.stopWorkers();
        });
      // 向线程中推入参数
      // 倒计时开始, 传入各种参数
      //   window.goWorkers.contributePresale(rpc);
    },
    stopWorkers() {
      this.submitLoading = false;
      window.goWorkers && window.goWorkers.stop();
    },
  },
};
</script>
<style lang="scss" scoped>
.config-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  &-item {
    flex: 1;
    margin-right: 20px;
    &-body {
      margin-top: 8px;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.label-row {
  display: flex;
  flex-direction: row;
}
.count-down-box {
  width: 100%;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>