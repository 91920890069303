<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <div class="mx-4 overflow-hidden" style="z-index: 2000; width: 300px">
        <select id="choices-gender" class="form-control">
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>

    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="../../../assets/img/bruce-mars.jpg"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">代币收录</h5>
            <p class="mb-0 text-sm font-weight-bold">TM最近收录的优质代币</p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item" @click="changeTab(0)">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="true"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            ></path>
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            ></path>
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">代币列表</span>
                </a>
              </li>
              <li class="nav-item" @click="changeTab(1)">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  aria-selected="false"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>document</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            ></path>
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">提交收录</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="overflow-scroll card">
          <div class="card-body d-flex">
            <div class="text-center col-lg-1 col-md-2 col-sm-3 col-4">
              <a
                href="javascript:;"
                class="
                  avatar avatar-lg
                  border-1
                  rounded-circle
                  bg-gradient-success
                "
              >
                <i class="text-white fas fa-plus"></i>
              </a>
              <p class="mb-0 text-sm" style="margin-top: 6px">提交代币</p>
            </div>
            <story-avatar
              v-for="({ name, image }, index) of stories"
              :key="index"
              :image="image"
              :name="name"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTab === 0" class="mt-4 row">
      <div class="col-12 col-lg-8">
        <post-card />
      </div>
      <div class="col-12 col-lg-4">
        <team-profile-card
          title="Digital Marketing"
          description="A group of people who collectively are responsible for all of the
              work necessary to produce working, validated assets."
          industry="Marketing Team"
          :rating="4.5"
          :members="[
            {
              name: 'Alexa Tompson',
              image: team1,
            },
            {
              name: 'Romina Hadid',
              image: team2,
            },
            {
              name: 'Alexander Smith',
              image: team3,
            },
            {
              name: 'Martin Doe',
              image: team4,
            },
          ]"
          :dropdown="[
            {
              label: 'Action',
              route: 'javascript:;',
            },
            {
              label: 'Another action',
              route: 'javascript:;',
            },
            {
              label: 'Something else here',
              route: 'javascript:;',
            },
          ]"
        />
        <team-profile-card
          title="Design"
          description="Because it's about motivating the doers. Because I’m here to
              follow my dreams and inspire other people to follow their dreams,
              too."
          industry="Design Team"
          :rating="5"
          :members="[
            {
              name: 'Martin Doe',
              image: team4,
            },
            {
              name: 'Alexander Smith',
              image: team3,
            },
            {
              name: 'Romina Hadid',
              image: team2,
            },
            {
              name: 'Alexa Tompson',
              image: team1,
            },
          ]"
          :dropdown="[
            {
              label: 'Action',
              route: 'javascript:;',
            },
            {
              label: 'Another action',
              route: 'javascript:;',
            },
            {
              label: 'Something else here',
              route: 'javascript:;',
            },
          ]"
        />
        <event-card
          id="902-128-281"
          title="Slack Meet"
          description="You have an upcoming meet for Marketing Planning"
          date-tim="11:00 AM"
          :image="slackLogo"
          :members="[
            { name: 'Alexa tompson', image: team1 },
            { name: 'Romina Hadid', image: team2 },
            { name: 'Alexander Smith', image: team3 },
            { name: 'Martin Doe', image: ivana },
          ]"
          :action="{
            route: '',
            label: 'Join',
            color: 'success',
          }"
        />
        <event-card
          id="111-968-981"
          title="Invision"
          description="You have an upcoming video call for
              <span class='text-primary'>Soft Design</span> at 5:00 PM."
          date-tim="4:50 PM"
          :image="invisionLogo"
          :members="[
            { name: 'Alexa tompson', image: team1 },
            { name: 'Romina Hadid', image: team2 },
            { name: 'Alexander Smith', image: team3 },
            { name: 'Martin Doe', image: ivana },
          ]"
          :action="{
            route: '',
            label: 'Join',
            color: 'primary',
          }"
        />
      </div>
    </div>
    <div v-if="currentTab === 1">
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="mx-auto col-lg-9 col-12">
            <div class="mt-4 card card-body">
              <h6 class="mb-0">申请收录</h6>
              <p class="mb-0 text-sm">
                填写表单后通过申请, 即可收录到Token Monitor中
              </p>
              <hr class="my-3 horizontal dark" />
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>选择主网</label>
                  <soft-input
                    id="firstName"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="eg. Michael"
                  />
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>合约地址</label>
                  <soft-input
                    id="lastName"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="eg. Prior"
                  />
                </div>
              </div>
              <label for="projectName" class="form-label">Project Name</label>
              <input id="projectName" type="text" class="form-control" />
              <div class="mt-4 row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label> Private Project </label>
                    <p class="text-xs form-text text-muted ms-1">
                      If you are available for hire outside of the current
                      situation, you can encourage others to hire you.
                    </p>
                    <div class="form-check form-switch ms-1">
                      <input
                        id="flexSwitchCheckDefault"
                        class="form-check-input"
                        type="checkbox"
                        data-type="warning"
                        data-content="Once a project is made private, you cannot revert it to a public project."
                        data-title="Warning"
                        data-icon="ni ni-bell-55"
                      />
                      <label
                        class="form-check-label"
                        for="flexSwitchCheckDefault"
                      ></label>
                    </div>
                  </div>
                </div>
              </div>
              <label class="mt-4">Project Description</label>
              <p class="text-xs form-text text-muted ms-1">
                This is how others will learn about the project, so make it
                good!
              </p>
              <div id="editor">
                <p>Hello World!</p>
                <p>Some initial <strong>bold</strong> text</p>
                <p><br /></p>
              </div>
              <label class="mt-4 form-label">Project Tags</label>
              <select
                id="choices-multiple-remove-button"
                class="form-control"
                name="choices-multiple-remove-button"
              >
                <option value="Choice 1" selected>Choice 1</option>
                <option value="Choice 2">Choice 2</option>
                <option value="Choice 3">Choice 3</option>
                <option value="Choice 4">Choice 4</option>
              </select>
              <div class="row">
                <div class="col-12">
                  <label class="form-label">Start Date</label>
                  <flat-pickr
                    v-model="date"
                    class="form-control datetimepicker"
                    placeholder="Please select start date"
                    :config="config"
                  ></flat-pickr>
                </div>
              </div>
              <label class="mt-4 form-label">Starting Files</label>
              <form
                id="dropzone"
                action="/file-upload"
                class="form-control dropzone"
              >
                <div class="fallback">
                  <input name="file" type="file" multiple />
                </div>
              </form>
              <div class="mt-4 d-flex justify-content-end">
                <button type="button" name="button" class="m-0 btn btn-light">
                  Cancel
                </button>
                <button
                  type="button"
                  name="button"
                  class="m-0 btn bg-gradient-success ms-2"
                >
                  Create Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import kal from "@/assets/img/kal-visuals-square.jpg";
import marie from "@/assets/img/marie.jpg";
import ivana from "@/assets/img/ivana-square.jpg";
import bruce from "../../../assets/img/bruce-mars.jpg";
import emma from "../../../assets/img/ivana-squares.jpg";
import nick from "@/assets/img/team-3.jpg";
import team1 from "../../../assets/img/team-1.jpg";
import team2 from "../../../assets/img/team-2.jpg";
import team3 from "../../../assets/img/team-3.jpg";
import team4 from "../../../assets/img/team-4.jpg";
import team5 from "../../../assets/img/team-5.jpg";
import slackLogo from "../../../assets/img/small-logos/logo-slack.svg";
import invisionLogo from "../../../assets/img/small-logos/logo-invision.svg";
import PostCard from "../profile/components/PostCard.vue";
import StoryAvatar from "../profile/components/StoryAvatar.vue";
import TeamProfileCard from "../profile/components/TeamProfileCard.vue";
import EventCard from "../profile/components/EventCard.vue";
import SoftInput from "@/components/SoftInput.vue";

import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";

import flatPickr from "vue-flatpickr-component";
import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill";

export default {
  name: "ProfileTeam",
  components: {
    TeamProfileCard,
    PostCard,
    StoryAvatar,
    EventCard,
    flatPickr,
    SoftInput,
  },
  data() {
    return {
      currentTab: 0,
      showMenu: false,

      form: {
        mainnet: "", // 主网
        contract: "", // 合约地址
        name: "", // 代币名称
        symbol: "", // 符号
        launch_at: "", // 启动时间
        description: "", // 项目介绍
        logo: "", // logo地址
        website: "",
        twitter: "",
        facebook: "",
        telegram: "",
        github: "",
        discord: "",
        instagram: "",
        reddit: "",
        mirror: "",
        youtube: "",
        audit_report: "", // 审计报告链接
      },
      date: "",
      endDate: "",
      config: {
        allowInput: true,
      },
      stories: [
        {
          name: "Abbie W",
          image: team1,
        },
        {
          name: "Boris U",
          image: team2,
        },
        {
          name: "Kay R",
          image: team3,
        },
        {
          name: "Tom M",
          image: team4,
        },
        {
          name: "Nicole N",
          image: team5,
        },
        {
          name: "Marie P",
          image: marie,
        },
        {
          name: "Bruce M",
          image: bruce,
        },
        {
          name: "Sandra A",
          image: ivana,
        },
        {
          name: "Katty L",
          image: kal,
        },
        {
          name: "Emma O",
          image: emma,
        },
        {
          name: "Tao G",
          image:
            "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/team-9.jpg",
        },
      ],
      nick,
      slackLogo,
      invisionLogo,
      kal,
      marie,
      ivana,
      bruce,
      emma,
      team1,
      team2,
      team3,
      team4,
      team5,
    };
  },

  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    changeTab(t) {
      this.currentTab = t;
      if (t === 1) {
        this.$nextTick(() => {
          console.log(document.getElementById("editor"), 222);
          if (document.getElementById("editor")) {
            new Quill("#editor", {
              theme: "snow", // Specify theme in configuration
            });
          }

          if (document.getElementById("choices-multiple-remove-button")) {
            var element = document.getElementById(
              "choices-multiple-remove-button"
            );
            console.log(element);
            const example = new Choices(element, {
              removeItemButton: true,
            });

            example.setChoices(
              [
                {
                  value: "One",
                  label: "Label One",
                  disabled: true,
                },
                {
                  value: "Two",
                  label: "Label Two",
                  selected: true,
                },
                {
                  value: "Three",
                  label: "Label Three",
                },
              ],
              "value",
              "label",
              false
            );
          }

          Dropzone.autoDiscover = false;
          var drop = document.getElementById("dropzone");
          new Dropzone(drop, {
            url: "/file/post",
            addRemoveLinks: true,
          });
        });
      }
    },
  },
};
</script>
