<template>
  <div class="form-check form-switch">
    <input
      :id="id || ''"
      v-model="c"
      class="form-check-input"
      :class="$attrs.class"
      type="checkbox"
      :name="name || ''"
      :checked="c"
      :value="1"
      @change="changeInput"
    />
    <label class="form-check-label" :class="labelClass" :for="id || ''">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "SoftSwitch",
  props: {
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      c: false,
    };
  },
  watch: {
    checked(v) {
      this.c = v;
    },
  },
  mounted() {
    this.c = this.checked;
  },
  methods: {
    changeInput() {
      console.log(this.c, "当前c");
      this.c = !this.c;
      this.$emit("input-value", this.c);
    },
  },
};
</script>
