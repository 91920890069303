<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success4 opacity-6"></span>
    </div>

    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="https://token-monitor.s3.amazonaws.com/1672739254216screenshot-20230103-174724.png"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>

        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">授信检查</h5>
            <p class="mb-0 text-sm font-weight-bold">可批量取消已授权代币</p>
            <p class="mb-0 text-sm font-weight-bold">
              <!-- ✔ 批量合约已开源 支持任何审计 -->
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col col-12 col-lg-8 col-md-8 col-sm-12 mt-2">
          <div class="row config-panel">
            <div style="flex: 1">
              <div class="config-panel">
                <div class="config-panel-item">
                  <label>取消授权方式</label>
                  <div class="config-panel-item-body">
                    <select v-model="cancelType" class="form-control">
                      <option
                        v-for="item in [
                          {
                            id: 'wallet',
                            name: '当前连接钱包',
                          },
                          {
                            id: 'private',
                            name: '私钥',
                          },
                        ]"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div style="flex: 1">
              <div class="config-panel">
                <div class="config-panel-item">
                  <label>公链</label>
                  <div class="config-panel-item-body">
                    <select
                      id="choices-gender"
                      v-model="selectChainId"
                      class="form-control"
                    >
                      <option
                        v-for="item in networks"
                        :key="item.chainId"
                        :value="item.chainId"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="cancelType === 'private'" style="flex: 1">
              <ConfigPanel :display-chain="false" :display-exchange="false">
              </ConfigPanel>
            </div>
          </div>
          <div class="table-responsive background-white mt-4">
            <div class="tx-amount-wallets">
              <label>授权列表</label>
              <div>
                <el-button
                  class="mini-btn cancel-btn"
                  type="text"
                  @click="cancelAll"
                  >全部取消</el-button
                >
              </div>
            </div>
            <table class="table align-items-center mb-0">
              <thead>
                <tr>
                  <th
                    class="
                      text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                    "
                  >
                    代币
                  </th>
                  <th
                    class="
                      text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                      ps-2
                    "
                  >
                    授权地址
                  </th>
                  <th
                    class="
                      text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                      ps-2
                    "
                  >
                    授权数量
                  </th>
                  <th
                    class="
                      text-center text-uppercase text-secondary text-xxs
                      font-weight-bolder
                      opacity-7
                    "
                  >
                    操作
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, ind) in list" :key="item.key">
                  <td>
                    <p class="text-sm text-secondary mb-0">
                      <!-- {{ getShortAddr(item.token) }} -->
                      {{ item.info.symbol }}
                      <el-icon @click="copyAddress(item.token)">
                        <copy-document />
                      </el-icon>
                    </p>
                  </td>
                  <td>
                    <p class="text-sm text-secondary mb-0">
                      {{ getShortAddr(item.sender) }}
                      <el-icon @click="copyAddress(item.sender)">
                        <copy-document />
                      </el-icon>
                    </p>
                  </td>
                  <td>
                    <p class="text-sm text-secondary mb-0">
                      {{ item.info.allowance }}
                    </p>
                  </td>

                  <td class="align-middle text-center text-sm">
                    <p class="text-sm text-secondary mb-0">
                      <el-button
                        v-if="item.status !== 1"
                        class="mini-btn cancel-btn"
                        type="text"
                        @click="
                          cancelApprove(
                            item.token,
                            item.sender,
                            ind,
                            item.info.symbol
                          )
                        "
                        >取消授权</el-button
                      >
                      <el-button
                        v-if="item.status === 1"
                        disabled
                        class="mini-btn cancel-btn"
                        type="text"
                        >已取消</el-button
                      >
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col col-12 col-md-4 col-lg-4 col-sm-12 mt-2">
          <div class="row">
            <div class="col-12">
              <soft-button v-if="!submitLoading" full-width @click="submit">
                开始检查
              </soft-button>
              <soft-button
                v-if="submitLoading"
                color="danger"
                full-width
                @click="stopSubmit"
              >
                <el-icon class="is-loading">
                  <loading />
                </el-icon>

                停止检查
              </soft-button>
            </div>
          </div>

          <div class="panel mt-2 row">
            <label>日志</label>
            <soft-textarea
              :scroll-to-top="true"
              :value="logs"
              :rows="15"
              placeholder="操作日志"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfigPanel from "@/components/CommonConfig/ConfigPanel.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import flatPickr from "vue-flatpickr-component";
import OptionDescription from "./components/OptionDescription.vue";
import {
  NETWORK_LIST,
  initProvider,
  tokenAbi,
  getPrividerWithChainId,
} from "@/utils/config";
import { ElIcon } from "element-plus";
import {
  InfoFilled,
  Loading,
  DeleteFilled,
  CopyDocument,
} from "@element-plus/icons-vue";
import axios from "axios";
import { Contract, Provider } from "ethers-multicall";
import { ethers } from "ethers";
// import { WorkerPool } from "@/utils/worker_pool";
import { toRaw } from "vue";
// const maxApprove = BigNumber.from(
//   "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
// );
export default {
  components: {
    ConfigPanel,
    SoftInput,
    SoftButton,
    OptionDescription,
    flatPickr,
    ElIcon,
    SoftTextarea,
    InfoFilled,
    Loading,
    SoftAlert,
    CopyDocument,
    DeleteFilled,
  },
  data() {
    return {
      cancelType: "wallet",
      selectChainId: 56,
      logs: "",
      submitLoading: false,
      list: [],
      tokenMap: {}, // 是否已经操作过了
    };
  },
  computed: {
    currentNetwork() {
      if (this.cancelType === "wallet") {
        return this.$store.state.loginNetwork;
      }
      return this.$store.state.network;
    },
    networks() {
      // 获取所有公链列表
      // 获取默认公链
      // 获取自定义公链
      // 合并返回
      const n = NETWORK_LIST;
      return n;
    },
  },
  methods: {
    async copyAddress(address) {
      await this.$copyText(address);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async cancelAll() {
      // 取消所有授权
      if (this.cancelType === "wallet") {
        this.$swal({
          title: "操作不支持",
          text: "批量取消授权仅支持使用私钥的方式",
        });
        return;
      }
      if (!this.currentNetwork.currentWallet) {
        this.$swal({
          title: "请加载钱包",
        });
        return;
      }

      let ind = 0;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (ind > this.list.length - 1) {
          break;
        }
        const a = this.list[ind];
        if (a.status !== 1) {
          // 调用操作
          await this.cancelApprove(a.token, a.sender, ind, a.info.symbol);
        }
        ind += 1;
      }
    },
    stopSubmit() {
      this.submitLoading = false;
      this.log(`操作已终止`);
    },
    async cancelApprove(tokenAddress, sender, ind, symbol) {
      // tokenAddress sender
      // 取消授权
      this.log(`正在取消代币 ${symbol} 对合约 ${sender} 的授权`);
      if (this.cancelType === "wallet") {
        // 通过metamask调用
        const provider = toRaw(this.currentNetwork.provider);
        const t = new ethers.Contract(
          tokenAddress,
          tokenAbi,
          provider.getSigner()
        );

        this.log(`即将调起钱包插件...`);
        const x = await t.approve(sender, ethers.utils.parseEther("0"));
        this.log(`取消授权交易已提交, 交易哈希: ${x.hash}`);
        this.list[ind]["status"] = 1;
      } else {
        // 通过私钥直接调用
        const p = getPrividerWithChainId(this.selectChainId);
        const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
        let tFace = new ethers.utils.Interface(tokenAbi);
        const dd = tFace.encodeFunctionData("approve", [
          sender,
          ethers.utils.parseEther("0"),
        ]);
        const approveTx = {
          from: this.currentNetwork.currentWallet,
          to: tokenAddress,
          data: dd,
          value: ethers.utils.parseEther("0"),
        };
        console.log(approveTx);
        const approveX = await w.sendTransaction(approveTx);
        this.log(`取消授权交易已提交, 交易哈希: ${approveX.hash}`);
        this.list[ind]["status"] = 1;
      }
    },
    log(str) {
      this.logs += "\n" + str;
      this.$nextTick(() => {
        console.log(this.logs);
      });
    },
    isAddress(address) {
      return address.toLowerCase().indexOf("0x") === 0 && address.length === 42;
    },
    getShortAddr(address) {
      if (address.length >= 42) {
        return address.slice(0, 4) + "..." + address.slice(address.length - 5);
      }
      return address;
    },
    async submit() {
      this.log("开始检查...");
      this.log(
        `开始筛选钱包地址 ${this.currentNetwork.currentWallet} 的所有交易...`
      );
      let page = 0;
      this.submitLoading = true;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const url = `https://api.covalenthq.com/v1/56/address/${this.currentNetwork.currentWallet}/transactions_v2/?key=ckey_1568366558604035b1bc58222dc&page-number=${page}&page-size=100`;
        this.log(`正在获取第 ${page + 1} 页...`);
        console.log(url);
        try {
          if (!this.submitLoading) {
            return;
          }
          const { data } = await axios(url);
          console.log(data);
          let _c = 0;
          for (let i = 0; i < data.data.items.length; i++) {
            const item = data.data.items[i];
            if (item.log_events && item.log_events.length > 0) {
              for (let j = 0; j < item.log_events.length; j++) {
                const iitem = item.log_events[j];
                if (
                  iitem.raw_log_topics &&
                  iitem.raw_log_topics.length > 0 &&
                  iitem.raw_log_topics[0].toLowerCase() ===
                    "0x8c5be1e5ebec7d5bd14f71427d1e84f3dd0314c0f7b2291e5b200ac8c7c3b925"
                ) {
                  const owner = iitem.decoded.params[0].value;
                  const tokenAddress = iitem.sender_address;
                  const sender = iitem.decoded.params[1].value;
                  const key = `${tokenAddress}_${sender}`;
                  if (
                    owner.toLowerCase() ===
                      this.currentNetwork.currentWallet.toLowerCase() &&
                    !this.tokenMap[key]
                  ) {
                    // 把授权信息写入到列表中
                    const d = {
                      token: iitem.sender_address,
                      owner: iitem.decoded.params[0].value,
                      sender: iitem.decoded.params[1].value,
                      value: iitem.decoded.params[2].value,
                    };
                    // 获取代币信息
                    // console.log(
                    //   "token地址为",
                    //   iitem.sender_address,
                    //   d,
                    //   item.tx_hash
                    // );
                    this.tokenMap[key] = true;
                    if (!this.submitLoading) {
                      return;
                    }
                    const token = await this.getTokenInfo(
                      tokenAddress,
                      owner,
                      sender
                    );
                    if (token && token.allowance > 0) {
                      this.list.push({
                        ...d,
                        info: token,
                        key,
                      });
                      _c += 1;
                    }
                  }
                }
              }
            }
          }
          this.log(`扫描到 ${_c} 条授权信息`);

          page += 1;
          if (!data.data.pagination.has_more) {
            break;
          }
        } catch (e) {
          this.log(JSON.parse(e));
        }
      }
      this.log(`全部交易扫描完成`);
      this.submitLoading = false;
    },
    async getTokenInfo(tokenAddress, owner, sender) {
      //   const provider = toRaw(this.currentNetwork.provider);
      const provider = getPrividerWithChainId(this.selectChainId);
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(tokenAddress, tokenAbi);

      //   try {
      const calls = [
        token.name(),
        token.symbol(),
        token.decimals(),
        token.totalSupply(),
        token.allowance(owner, sender),
      ];

      const res = await ethcallProvider.all(calls);
      return {
        name: res[0],
        symbol: res[1],
        decimals: parseInt(res[2].toString()),
        totalSupply:
          res[3].toString() / Math.pow(10, parseInt(res[2].toString())),
        allowance:
          res[4].toString() / Math.pow(10, parseInt(res[2].toString())),
      };
      //   } catch (e) {
      //     console.log(e);
      //   }
    },
  },
};
</script>
<style lang="scss" scoped>
.config-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  &-item {
    flex: 1;
    margin-right: 20px;
    &-body {
      margin-top: 8px;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
.label-row {
  display: flex;
  flex-direction: row;
}
.count-down-box {
  width: 100%;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bg-gradient-success4 {
  background-image: linear-gradient(310deg, #294c31 0%, #ccbcec 100%);
}
.tx-amount-wallets {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.background-white {
  background-color: #ffffff;
  border-radius: 10px;
}
.cancel-btn {
  font-size: 12px;
  // cursor: pointer;
}
</style>