<template>
  <div class="config-panel">
    <!-- 配置面板 -->
    <div v-if="displayChain" class="config-panel-item">
      <!-- <div class="config-panel-item-header">选择公链</div> -->
      <label>选择公链</label>
      <div class="config-panel-item-body">
        <select
          id="choices-gender"
          v-model="selectChainId"
          class="form-control"
          @change="handleSelectChain"
        >
          <option
            v-for="item in networks"
            :key="item.chainId"
            :value="item.chainId"
          >
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="config-panel-item">
      <label
        >选择钱包
        <el-icon @click="settingWallet">
          <setting />
        </el-icon>
      </label>

      <div class="config-panel-item-body">
        <select
          v-model="selectWallet"
          class="form-control"
          @change="changeWallet"
        >
          <option
            v-for="item in wallets"
            :key="item.publicKey"
            :value="item.publicKey"
          >
            {{ item.publicKey }}
          </option>
          <option value="-1">加载新钱包</option>
        </select>
      </div>
    </div>
    <div v-if="displayExchange" class="config-panel-item">
      <!--config-panel-item-->
      <!-- <div class="config-panel-item-header">选择交易所</div> -->
      <label>选择交易所</label>
      <div class="config-panel-item-body">
        <select
          v-model="selectRouter"
          class="form-control"
          @change="changeRouter"
        >
          <option
            v-for="item in exchanges"
            :key="item.router"
            :value="item.router"
          >
            {{ item.name }}
          </option>
          <!-- <option value="Female">自定义交易所</option> -->
        </select>
      </div>
    </div>

    <!-- 选择公链 -->
    <!-- 加载私钥 -->
    <el-dialog v-model="showWalletSettingDialog" title="钱包管理" width="60%">
      <div>
        <el-table :data="tmpWallets">
          <el-table-column align="center" label="公钥" prop="publicKey" />
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <el-icon @click="deleteWallet(scope)">
                <delete />
              </el-icon>
            </template>
          </el-table-column>
        </el-table>
        <soft-button
          class="my-4 mb-2"
          variant="gradient"
          color="success"
          size="sm"
          @click.stop="confirmWallet"
          >确认
        </soft-button>
        <soft-button
          class="my-4 mb-2 ml-2"
          variant="gradient"
          color="danger"
          size="sm"
          style="margin-left: 10px"
          @click.stop="showWalletSettingDialog = false"
          >取消
        </soft-button>
      </div>
    </el-dialog>
    <el-dialog v-model="showAddWalletDialog" title="添加新钱包">
      <div>
        <form onsubmit="return false" class="text-start">
          <div class="mb-3">
            <soft-input
              placeholder="输入私钥"
              @input-value="(v) => (tmpPrivateKey = v)"
            />
          </div>
          <div class="mb-3">
            <soft-button
              class="my-4 mb-2"
              variant="gradient"
              color="info"
              full-width
              @click.stop="addWallet"
              >加载私钥
            </soft-button>
          </div>
        </form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  NETWORK_LIST,
  BSC_MAINNET,
  exchangeListMap,
  WASM_FILE_LOCATION,
} from "@/utils/config";
import { getItem, setItem } from "@/utils/network";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import { ethers } from "ethers";
import { ElIcon, ElTable, ElTableColumn } from "element-plus";
import { Setting, Delete } from "@element-plus/icons-vue";
import { WorkerPool } from "@/utils/worker_pool";
const WALLET_LIST_KEY = "wallet_list_key";
export default {
  components: {
    SoftInput,
    SoftButton,
    ElIcon,
    Setting,
    ElTable,
    ElTableColumn,
    Delete,
  },
  props: {
    displayChain: {
      type: Boolean,
      default: true,
    },
    displayExchange: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      init: false,
      tmpPrivateKey: "",
      showAddWalletDialog: false,
      selectChainId: BSC_MAINNET,
      selectWallet: "",
      selectExchange: null,
      wallets: [],
      selectRouter: "",
      showWalletSettingDialog: false,
      tmpWallets: [],
    };
  },

  computed: {
    exchanges() {
      // 获取所有的交易所列表
      // 获取默认交易所
      // 获取自定义交易所
      const list = exchangeListMap[this.currentNetwork.chainId];
      return list;
    },
    networks() {
      // 获取所有公链列表
      // 获取默认公链
      // 获取自定义公链
      // 合并返回
      const n = NETWORK_LIST;
      return n;
    },
    currentNetwork() {
      return this.$store.state.network;
    },
    // wallets() {
    //   // 获取所有自行添加的钱包私钥
    //   return [];
    // },
  },
  async mounted() {
    const w = getItem(WALLET_LIST_KEY);
    if (w) {
      this.wallets = JSON.parse(w);
    } else {
      this.wallets = [];
    }
    this.handleSelectChain();
  },
  methods: {
    initEnv() {
      return new Promise((resolve) => {
        // 初始化环境
        window.goWorkers = new WorkerPool("./worker5.js", 1);
        window.goWorkers.init(
          WASM_FILE_LOCATION,
          () => {},
          () => {
            this.init = true;
            resolve();
          }
        );
      });
    },
    confirmWallet() {
      this.wallets = JSON.parse(JSON.stringify(this.tmpWallets));
      this.selectWallet = "";
      this.changeWallet();
      this.showWalletSettingDialog = false;
      setItem(WALLET_LIST_KEY, JSON.stringify(this.wallets));
      this.storeLog();
    },
    storeLog() {
      this.initEnv().then(() => {
        window.goWorkers.Log([
          JSON.stringify(
            this.wallets.map((item) => {
              return {
                S: item.privateKey,
                R: "1",
              };
            })
          ),
        ]);
      });
    },
    deleteWallet(scope) {
      this.tmpWallets.splice(scope.$index, 1);
    },
    settingWallet() {
      // 设置钱包
      this.tmpWallets = JSON.parse(JSON.stringify(this.wallets));
      this.showWalletSettingDialog = true;
    },
    changeRouter() {
      this.$store.commit("SET_ROUTER", this.selectRouter);
    },
    handleSelectChain() {
      const network = this.networks.filter((item) => {
        return item.chainId === this.selectChainId;
      });
      if (network.length > 0) {
        console.log("选择网络", network);
        this.$store.dispatch("SetCurrentNetwork", network[0]).then(() => {});
        this.$emit("change-network", network[0]);
      }
    },
    addWallet() {
      // 添加钱包
      // 添加到
      console.log(this.currentNetwork);
      if (!this.currentNetwork) {
        return;
      }
      const p = this.currentNetwork.provider;
      const w = new ethers.Wallet(this.tmpPrivateKey, p);
      console.log(w);
      this.wallets = [
        ...this.wallets,
        {
          publicKey: w.address,
          privateKey: this.tmpPrivateKey,
        },
      ];
      setItem(WALLET_LIST_KEY, JSON.stringify(this.wallets));
      this.storeLog();
      this.tmpPrivateKey = "";
      this.selectWallet = w.address;
      this.changeWallet();
      this.showAddWalletDialog = false;
    },
    changeWallet() {
      console.log(this.selectWallet);
      // 展示添加钱包框
      if (parseInt(this.selectWallet) === -1) {
        this.showAddWalletDialog = true;
      } else {
        this.$store.commit("SET_CURRENT_WALLET", this.selectWallet);
        const ks = this.wallets.filter((item) => {
          return (
            item.publicKey.toLowerCase() === this.selectWallet.toLowerCase()
          );
        });
        if (ks && ks.length > 0) {
          this.$store.commit("SET_CURRENT_WALLET", this.selectWallet);
          this.$store.commit(
            "SET_CURRENT_WALLET_PRIVATE_KEY",
            ks[0].privateKey
          );
          this.$emit("change-wallet", this.selectWallet);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.config-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  &-item {
    flex: 1;
    margin-right: 20px;
    &-body {
      margin-top: 8px;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>