import { ethers } from 'ethers'
const loginNetwork = {
    state: {
        chainId: 0, // 当前的networId
        name: '',
        shortname: '',
        provider: null,
        logo: '',
        currentWallet: '',
        currency: '',
        privateKey: '',
        router: '', // 选择的交易所地址
        vipInviter: '', //邀请者
        vipPlans: [], // vip版本
        vipInfo: [], // 每个版本的信息
    },
    mutations: {
        LOGIN_SET_CURRENCY: (state, currency) => {
            state.currency = currency
        },
        LOGIN_SET_LOGO: (state, logo) => {
            state.logo = logo
        },
        LOGIN_SET_CHAIN_ID: (state, chainId) => {
            state.chainId = chainId
        },
        LOGIN_SET_NAME: (state, name) => {
            state.name = name
        },
        LOGIN_SET_SHORTNAME: (state, name) => {
            state.shortname = name
        },
        LOGIN_SET_PROVIDER: (state, provider) => {
            state.provider = provider
        },
        LOGIN_SET_CURRENT_WALLET: (state, w) => {
            state.currentWallet = w
        },
        LOGIN_SET_CURRENT_WALLET_PRIVATE_KEY: (state, w) => {
            state.privateKey = w
        },
        LOGIN_SET_ROUTER: (state, v) => {
            state.router = v
        },
        SET_VIP_VERSION: (state, v) => {
            state.vipPlans = v
        },
        SET_INVITE_ADDRESS: (state, v) => {
            state.vipInviter = v
        },
        SET_VIP_INFO: (state, v) => {
            state.vipInfo = v
        }

    },
    actions: {
        SetLoginNetwork({ commit }, network) {
            return new Promise((resolve) => {
                commit('LOGIN_SET_CHAIN_ID', network.chainId)
                commit('LOGIN_SET_NAME', network.name)
                commit('LOGIN_SET_SHORTNAME', network.shortname)
                commit('LOGIN_SET_LOGO', network.logo)
                commit('LOGIN_SET_CURRENCY', network.nativeCurrency.symbol)
                const nodes = network.rpcUrls
                const index = Math.floor((Math.random() * nodes.length))
                let provider = null
                if (window.ethereum) {
                    provider = new ethers.providers.Web3Provider(window.ethereum)
                } else {
                    provider = new ethers.providers.JsonRpcProvider(nodes[index])
                }

                commit('LOGIN_SET_PROVIDER', provider)
                resolve()
            })
        },
        SetLoginWallet({ commit }) {
            return new Promise((resolve, reject) => {
                if (window.ethereum) {
                    window.ethereum
                        .request({ method: 'eth_requestAccounts' })
                        .then(a => {
                            commit('LOGIN_SET_CURRENT_WALLET', a[0])
                            const provider = new ethers.providers.Web3Provider(window.ethereum)
                            // console.log('providerrrr', provider)
                            commit('LOGIN_SET_PROVIDER', provider)
                            resolve()
                        })
                        .catch((err) => {
                            if (err.code === 4001) {
                                commit('LOGIN_SET_CURRENT_WALLET', '')
                            } else {
                                // console.error(err);
                                // reject(err)
                                commit('LOGIN_SET_CURRENT_WALLET', '')
                            }
                            reject(err)
                        })
                }
            })
        }
    }
}
export default loginNetwork