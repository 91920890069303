<template>
  <div class="form-group">
    <label v-if="id" :for="id">
      <slot />
    </label>
    <textarea
      ref="input-area"
      :id="id || ''"
      v-model="input"
      class="form-control"
      style="padding-bottom: 50px"
      :rows="rows"
      :placeholder="placeholder"
      @input="changeValue"
    ></textarea>
  </div>
</template>

<script>
import { toRaw } from "vue";
export default {
  name: "SoftTextarea",
  props: {
    id: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 5,
    },
    value: {
      type: String,
      default: "",
    },
    scrollToTop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    value(v) {
      this.input = v;
      this.scroll();
    },
  },
  mounted() {
    this.input = this.value;
    this.scroll();
  },
  methods: {
    scroll() {
      // 滑动到最下面
      if (this.scrollToTop) {
        toRaw(this.$refs["input-area"]).scrollTop =
          toRaw(this.$refs["input-area"]).scrollHeight + 250;
      }
    },
    changeValue() {
      this.$emit("input-value", this.input);
      this.scroll();
    },
  },
};
</script>
