<template>
  <!-- <navbar /> -->
  <div
    class="m-3 page-header bg-gradient-success position-relative border-radius-xl"
  >
    <div class="container pb-10 pb-lg-9 pt-7 postion-relative z-index-2">
      <div class="row master-card-center">
        <div class="col-lg-8 col-md-8 col-12">
          <master-card />
        </div>
      </div>
      <div class="row">
        <div class="mx-auto text-center col-md-6">
          <!-- <h3 class="text-white">选择你的付费计划</h3> -->
          <!-- <p class="text-white">
            <template
              v-if="currentNetwork.vipInfo && currentNetwork.vipInfo.length > 0"
            >
              <div
                v-for="(item, ind) in currentNetwork.vipInfo"
                :key="item.expiredAt"
              >
                当前账号为 {{ getPlanName(ind) }}, 过期时间:
                {{ getTimeStr(item) }}
              </div>
            </template>
          </p> -->
        </div>
      </div>
      <div class="row">
        <div class="mx-auto text-center col-lg-4 col-md-6 col-7">
          <div class="mt-5 nav-wrapper position-relative z-index-2">
            <ul
              id="tabs-pricing"
              class="flex-row p-1 nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-1"
                  class="mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="#days"
                  role="tab"
                  aria-controls="days"
                  aria-selected="true"
                >
                  按天试用
                </a>
              </li>
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-2"
                  class="mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="#annual"
                  role="tab"
                  aria-controls="annual"
                  aria-selected="false"
                >
                  按周付费
                </a>
              </li>
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-3"
                  class="mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="#monthly"
                  role="tab"
                  aria-controls="monthly"
                  aria-selected="false"
                >
                  按月付费
                </a>
              </li>
              <li class="nav-item">
                <a
                  id="tabs-iconpricing-tab-4"
                  class="mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="#quarter"
                  role="tab"
                  aria-controls="quarter"
                  aria-selected="false"
                >
                  按季度付费
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-n8">
    <div class="container">
      <div class="tab-content tab-space">
        <div id="days" class="tab-pane active">
          <div class="row">
            <pricing-card
              v-if="
                currentNetwork &&
                currentNetwork.vipPlans &&
                currentNetwork.vipPlans.length > 0
              "
              :badge="{ color: 'secondary', label: '开发者版' }"
              :price="{
                currency: currentNetwork.vipPlans[0].price + 'BNB',
                value: '',
              }"
              :specifications="[
                { label: '刷交易量', includes: true },
                { label: '一键发币靓号生成', includes: true },
                { label: '多线程生成靓号钱包', includes: true },
                { label: '多线程生成靓号合约', includes: true },
                { label: '批量空投工具', includes: true },
                { label: '更多工具正在开发中', includes: true },
              ]"
              :action="{
                color: 'dark',
                route: 'javascript:;',
                label: '加入',
              }"
              :loading="joinLoadings[0] || false"
              @join="joinPlan(0, currentNetwork.vipPlans[0].price)"
            />
          </div>
        </div>
        <div id="annual" class="tab-pane">
          <div class="row">
            <!-- <pricing-card
              :badge="{ color: 'secondary', label: 'Starter' }"
              :price="{ currency: '$', value: '119' }"
              :specifications="[
                { label: '2 team members', includes: true },
                { label: '20GB Cloud storage', includes: true },
                { label: 'Integration help', includes: false },
                { label: 'Sketch Files', includes: false },
                { label: 'API Access', includes: false },
                { label: 'Complete documentation', includes: false },
              ]"
              :action="{
                color: 'dark',
                route: 'javascript:;',
                label: 'Join',
              }"
            />
            <pricing-card
              :badge="{ color: 'secondary', label: 'premium' }"
              :price="{ currency: '$', value: '159' }"
              :specifications="[
                { label: '10 team members', includes: true },
                { label: '40GB Cloud storage', includes: true },
                { label: 'Integration help', includes: true },
                { label: 'Sketch Files', includes: true },
                { label: 'API Access', includes: false },
                { label: 'Complete documentation', includes: false },
              ]"
              :action="{
                color: 'success',
                route: 'javascript:;',
                label: 'Join',
              }"
            /> -->

            <pricing-card
              v-if="
                currentNetwork &&
                currentNetwork.vipPlans &&
                currentNetwork.vipPlans.length > 0
              "
              :badge="{ color: 'secondary', label: '开发者版' }"
              :price="{
                currency: currentNetwork.vipPlans[1].price + 'BNB',
                value: '',
              }"
              :specifications="[
                { label: '刷交易量', includes: true },
                { label: '一键发币靓号生成', includes: true },
                { label: '多线程生成靓号钱包', includes: true },
                { label: '多线程生成靓号合约', includes: true },
                { label: '批量空投工具', includes: true },
                { label: '更多工具正在开发中', includes: true },
              ]"
              :action="{
                color: 'dark',
                route: 'javascript:;',
                label: '加入',
              }"
              :loading="joinLoadings[1] || false"
              @join="joinPlan(1, currentNetwork.vipPlans[1].price)"
            />
          </div>
        </div>
        <div id="monthly" class="tab-pane">
          <div class="row">
            <pricing-card
              v-if="
                currentNetwork &&
                currentNetwork.vipPlans &&
                currentNetwork.vipPlans.length > 0
              "
              :badge="{ color: 'secondary', label: '开发者版' }"
              :price="{
                currency: currentNetwork.vipPlans[2].price + 'BNB',
                value: '',
              }"
              :specifications="[
                { label: '刷交易量', includes: true },
                { label: '一键发币靓号生成', includes: true },
                { label: '多线程生成靓号钱包', includes: true },
                { label: '多线程生成靓号合约', includes: true },
                { label: '批量空投工具', includes: true },
                { label: '更多工具正在开发中', includes: true },
              ]"
              :action="{
                color: 'dark',
                route: 'javascript:;',
                label: '加入',
              }"
              :loading="joinLoadings[2] || false"
              @join="joinPlan(2, currentNetwork.vipPlans[2].price)"
            />
          </div>
        </div>
        <div id="quarter" class="tab-pane">
          <div class="row">
            <pricing-card
              v-if="
                currentNetwork &&
                currentNetwork.vipPlans &&
                currentNetwork.vipPlans.length > 0
              "
              :badge="{ color: 'secondary', label: '开发者版' }"
              :price="{
                currency: currentNetwork.vipPlans[3].price + 'BNB',
                value: '',
              }"
              :specifications="[
                { label: '刷交易量', includes: true },
                { label: '一键发币靓号生成', includes: true },
                { label: '多线程生成靓号钱包', includes: true },
                { label: '多线程生成靓号合约', includes: true },
                { label: '批量空投工具', includes: true },
                { label: '更多工具正在开发中', includes: true },
              ]"
              :action="{
                color: 'dark',
                route: 'javascript:;',
                label: '加入',
              }"
              :loading="joinLoadings[3] || false"
              @join="joinPlan(3, currentNetwork.vipPlans[3].price)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <app-footer /> -->
</template>
<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import PricingCard from "../components/PricingCard.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import AccordionItem from "../components/AccordionItem.vue";
import Accordion from "../components/Accordion.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { BSC_MAINNET, vipAbi, VIP_CONTRACT } from "@/utils/config";
import { toRaw } from "vue";
import { ethers, BigNumber } from "ethers";
import bus from "vue3-eventbus";

import MasterCard from "@/examples/Cards/MasterCard.vue";
const KEY_STORE_INVITER = "store_inviter_vip";
// import { toRaw } from 'vue'
export default {
  name: "Pricing",
  components: {
    MasterCard,
    Navbar,
    PricingCard,
    AppFooter,
    AccordionItem,
    Accordion,
    SoftAlert,
  },
  data() {
    return {
      joinLoadings: {},
    };
  },
  computed: {
    currentNetwork() {
      return this.$store.state.loginNetwork;
    },
  },
  mounted() {
    // this.$store.state.showSidenav = false;
    // this.$store.state.showNavbar = false;
    // this.$store.state.showFooter = false;

    const { inviter = "" } = this.$route.query;
    if (inviter && this.isAddress(inviter)) {
      // 保存邀请数据
      const d = {
        date: Date.parse(new Date()) / 1000,
        inviter: inviter,
      };
      localStorage.setItem(KEY_STORE_INVITER, JSON.stringify(d));
    }
    // 在这里查询价格
    this.fetchData();
  },
  beforeUnmount() {
    // this.$store.state.showSidenav = true;
    // this.$store.state.showNavbar = true;
    // this.$store.state.showFooter = true;
    // if (this.$store.state.isPinned === false) {
    //   const sidenav_show = document.querySelector(".g-sidenav-show");
    //   sidenav_show.classList.remove("g-sidenav-hidden");
    //   sidenav_show.classList.add("g-sidenav-pinned");
    //   this.$store.state.isPinned = true;
    // }
  },
  methods: {
    isAddress(address) {
      return address.toLowerCase().indexOf("0x") === 0 && address.length === 42;
    },
    // 格式化时间

    getTimeStr(timestamp) {
      const now = new Date(parseInt(timestamp) * 1000);

      var year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hour = now.getHours(),
        minute = now.getMinutes(),
        second = now.getSeconds();

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    getPlanName(planIndex) {
      if (planIndex === 0) return "开发者版按天试用VIP";
      if (planIndex === 1) return "开发者版按周付费VIP";
    },
    async fetchData() {
      // 获取当前的付费进度
      // tx链的付费合约是 0x9F77166f538137981e6A7c7bcc868c4be8BBc65E
    },
    async joinPlan(_index, price) {
      // 调起支付
      try {
        if (!this.currentNetwork.currentWallet) {
          this.$swal({
            title: "请先连接BSC钱包",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }
        if (this.currentNetwork.chainId !== BSC_MAINNET) {
          // bsc主网钱包
          this.$swal({
            title: "请切换到BSC主网",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }

        if (window.ethereum) {
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
          });

          console.log(
            parseInt(BigNumber.from(chainId).toString()),
            this.currentNetwork.chainId,
            "当前网络"
          );
          if (
            parseInt(BigNumber.from(chainId).toString()) !==
            this.currentNetwork.chainId
          ) {
            this.$swal({
              title: "请将钱包切换到BSC主网",
              timer: 1200,
              timerProgressBar: true,
            });
            return;
          }
        }

        this.joinLoadings[_index] = true;
        const provider = toRaw(this.currentNetwork.provider);
        console.log(provider, this.currentNetwork.currentWallet);
        const c = new ethers.Contract(
          VIP_CONTRACT[BSC_MAINNET],
          vipAbi,
          provider.getSigner()
        );
        let inviter = "0x0000000000000000000000000000000000000000";
        const cc = localStorage.getItem(KEY_STORE_INVITER);
        const now = Date.parse(new Date()) / 1000;
        if (cc) {
          const ccj = JSON.parse(cc);
          if (
            ccj &&
            ccj.inviter !== this.currentNetwork.currentWallet &&
            parseInt(ccj.date) > now - 3600 * 3 &&
            this.isAddress(ccj.inviter)
          ) {
            inviter = ccj.inviter;
          }
        }

        console.log("邀请者是", inviter);

        const x = await c.startVip(
          ethers.utils.parseUnits(_index + "", 0),
          inviter,
          {
            value: ethers.utils.parseEther(price + ""),
          }
        );
        await x.wait();
        bus.emit("vipRefresh");
        this.$swal({
          title: "开通VIP成功",
        });
      } catch (e) {
        console.log(e, "eee");
        let r = JSON.stringify(e);
        if (e.reason) r = e.reason;
        if (e.data && e.data.message) r = e.data.message;
        if (r) {
          this.$swal({
            title: "支付失败",
            text: r,
          });
        }
      }
      this.joinLoadings[_index] = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.master-card-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
