<template>
  <ul class="navbar-nav justify-content-end">
    <li
      v-if="currentNetwork.vipInfo && currentNetwork.vipInfo.length > 0"
      class="nav-item d-flex align-items-center"
      @click="jumpToVip"
    >
      <img
        style="width: 30px; height: 30px; margin-right: 10px"
        src="https://cdn-icons-png.flaticon.com/128/7614/7614989.png"
        alt=""
      />
    </li>
    <li class="nav-item d-flex align-items-center">
      <soft-button
        class="top-btn"
        size="md"
        color="other "
        variant="outline"
        @click="showSelectNetworkDialog"
      >
        <div class="select-network-btn">
          <!-- <soft-avatar
                  v-if="currentNetwork.logo"
                  :width="20"
                  :height="20"
                  size="sm"
                  :img="currentNetwork.logo"
                /> -->

          <img
            v-if="currentNetwork.logo"
            style="width: 20px; height: 20px"
            :src="currentNetwork.logo"
            alt=""
          />

          <div class="select-network-btn-text">
            {{
              typeof currentNetwork.name === "object"
                ? ""
                : currentNetwork.name || "选择网络"
            }}
          </div>
        </div>
      </soft-button>
    </li>
    <li class="nav-item d-flex align-items-center">
      <soft-button
        v-if="!currentNetwork.currentWallet"
        class="top-btn"
        size="md"
        color="primary "
        @click="connectWallet"
      >
        连接钱包
      </soft-button>
      <soft-button
        v-if="currentNetwork.currentWallet"
        class="top-btn"
        size="md"
        color="primary "
        variant="outline"
        @click="disconnectWallet"
      >
        {{ getShortAddr(currentNetwork.currentWallet) }}
      </soft-button>
    </li>
    <!-- <li class="nav-item d-flex align-items-center">
            <router-link
              v-if="!currentUser.nickname"
              :to="{ name: 'Login' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <i class="fa fa-user" :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"></i>
              <span v-if="isRTL" class="d-sm-inline d-none">يسجل دخول</span>
              <span v-else class="d-sm-inline d-none">Sign In </span>
            </router-link>
            <router-link
              v-if="currentUser.nickname"
              :to="{ name: 'Login' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <img
                v-if="currentUser.avatar"
                class="me-sm-1 navbar-avatar"
                :src="currentUser.avatar"
                alt=""
              />
              <i
                v-else
                class="fa fa-user"
                :class="isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>
              <span class="d-sm-inline d-none"
                >{{ currentUser.nickname }}
              </span>
            </router-link>
          </li> -->
    <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
      <a
        id="iconNavbarSidenav"
        href="javascript:void(0)"
        class="p-0 nav-link text-body"
        @click="toggleSidebar"
      >
        <div class="sidenav-toggler-inner">
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
          <i class="sidenav-toggler-line"></i>
        </div>
      </a>
    </li>
    <!-- <li class="px-3 nav-item d-flex align-items-center">
            <a
              class="p-0 nav-link"
              :class="textWhite ? textWhite : 'text-body'"
              @click="toggleConfigurator"
            >
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </a>
          </li>
          <li
            class="nav-item dropdown d-flex align-items-center"
            :class="isRTL ? 'ps-2' : 'pe-2'"
          >
            <a
              id="dropdownMenuButton"
              href="#"
              class="p-0 nav-link"
              :class="[
                textWhite ? textWhite : 'text-body',
                showMenu ? 'show' : '',
              ]"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="cursor-pointer fa fa-bell"></i>
            </a>
            <ul
              class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/team-2.jpg"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New message</span> from
                        Laur
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        13 minutes ago
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="mb-2">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/small-logos/logo-spotify.svg"
                        class="avatar avatar-sm bg-gradient-dark me-3"
                        alt="logo spotify"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">New album</span> by
                        Travis Scott
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        1 day
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div
                      class="
                        my-auto
                        avatar avatar-sm
                        bg-gradient-secondary
                        me-3
                      "
                    >
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 43 36"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                      >
                        <title>credit-card</title>
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            transform="translate(-2169.000000, -745.000000)"
                            fill="#FFFFFF"
                            fill-rule="nonzero"
                          >
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(453.000000, 454.000000)">
                                <path
                                  class="color-background"
                                  d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                  opacity="0.593633743"
                                ></path>
                                <path
                                  class="color-background"
                                  d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        Payment successfully completed
                      </h6>
                      <p class="mb-0 text-xs text-secondary">
                        <i class="fa fa-clock me-1"></i>
                        2 days
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li> -->
    <el-dialog v-model="selectNetworkDialog" title="选择网络" width="60%">
      <div>
        <div>主网</div>
        <div class="network-list">
          <network-item
            v-for="item in mainnetList"
            :key="item.name"
            :is-active="currentNetwork.shortname === item.shortname"
            :text="item.name"
            :logo="item.logo"
            :chain-id="item.chainId"
            @select="selectInterNetwork"
          />
        </div>
      </div>
      <div>
        <div>测试网</div>
        <div class="network-list">
          <network-item
            v-for="item in testnetList"
            :key="item.name"
            :is-active="currentNetwork.shortname === item.shortname"
            :text="item.name"
            :logo="item.logo"
            :chain-id="item.chainId"
            @select="selectInterNetwork"
          />
        </div>
      </div>
    </el-dialog>
  </ul>
</template>
<script>
import { ElDialog } from "element-plus";
import { NETWORK_LIST, VIP_CONTRACT } from "@/utils/config";
import SoftButton from "@/components/SoftButton.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import NetworkItem from "./NetworkItem.vue";
const fatsaleNetworkCacheKey = "fatsale_network_cache_key";
import { toRaw } from "vue";
import { BigNumber, ethers } from "ethers";
import bus from "vue3-eventbus";
import { mapMutations, mapActions } from "vuex";
import {
  BSC_MAINNET,
  getPrividerWithChainId,
  initProvider,
  vipAbi,
} from "@/utils/config";
import { Contract, Provider } from "ethers-multicall";
export default {
  components: {
    // Breadcrumbs,
    SoftButton,
    ElDialog,
    NetworkItem,
    SoftAvatar,
  },
  data() {
    return {
      networkList: NETWORK_LIST,
      showMenu: false,
      currentUser: {},
      selectNetworkDialog: false,
    };
  },
  computed: {
    currentNetwork() {
      return this.$store.state.loginNetwork;
    },
    mainnetList() {
      return this.networkList.filter((item) => {
        return item.isTestNetwork !== true;
      });
    },
    testnetList() {
      return this.networkList.filter((item) => {
        return item.isTestNetwork === true;
      });
    },
  },
  mounted() {
    this.fetchData();

    bus.on("vipRefresh", () => {
      console.log("刷新vip状态");
      this.syncVipStatus();
    });
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),
    async jumpToVip() {
      this.$router.push({
        path: "/vip",
      });
    },
    async fetchData() {
      // 获取当前的用户信息
      // 显示在上面
      if (window.ethereum) {
        // 尝试获取chainId
        window.ethereum
          .request({ method: "eth_chainId" })
          .then((hexChainId) => {
            const cid = parseInt(BigNumber.from(hexChainId).toString());
            this.selectInterNetwork(cid);
          });
      }

      setTimeout(() => {
        // 验证vip状态
        this.syncVipStatus();
      }, 1000);
    },
    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },
    async syncVipStatus() {
      // 获取当前账户的vip信息
      // 获取账户信息
      //   const chainId =
      const provider = getPrividerWithChainId(BSC_MAINNET);

      if (!provider) return;
      // 获取当前账户在每一个上面的信息
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(ethcallProvider, BSC_MAINNET);
      const c = new Contract(VIP_CONTRACT[BSC_MAINNET], vipAbi);
      const calls = [0, 1, 2, 3].map((item) => {
        return c.plans(ethers.utils.parseUnits(item + "", 0));
      });
      const res = await ethcallProvider.all(calls);
      this.$store.commit(
        "SET_VIP_VERSION",
        res
          .filter((item) => parseInt(item.circle.toString()) > 0)
          .map((item) => {
            return {
              index: parseInt(item.index.toString()),
              circle: parseInt(item.circle.toString()),
              price: item.price.toString() / 1e18,
            };
          })
      );
      if (!this.currentNetwork.currentWallet) return;
      const now = Date.parse(new Date()) / 1000;

      const cs = [0, 1, 2, 3].map((item) => {
        return c.getVipInfo(
          this.currentNetwork.currentWallet,
          ethers.utils.parseUnits(item + "", 0)
        );
      });
      const vipInfo = await ethcallProvider.all([
        ...cs,
        c.inviterMap(this.currentNetwork.currentWallet),
      ]);

      this.$store.commit("SET_INVITE_ADDRESS", vipInfo[4]);
      const finalVipInfo = vipInfo
        .slice(0, 4)
        .filter(
          (item) =>
            parseInt(item.toString()) > 0 && parseInt(item.toString()) > now
        )
        .map((item) => {
          return item.toString();
        });
      console.log(vipInfo, finalVipInfo, "vipiiii");

      this.$store.commit("SET_VIP_INFO", finalVipInfo);
    },
    async disconnectWallet() {
      console.log("断开连接");
      const r = await this.$swal({
        title: "断开连接?",
        text: `确认断开钱包 ${this.currentNetwork.currentWallet} 的连接吗`,
        showCancelButton: true,
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        reverseButtons: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        buttonsStyling: false,
      });
      if (!r.isConfirmed) return;
      this.$store.commit("LOGIN_SET_CURRENT_WALLET", "");
    },
    async connectWallet() {
      if (!window.ethereum) {
        this.$swal({
          title: "请安装Metamask插件",
          timer: 2000,
          timerProgressBar: true,
        });
        return;
      }

      this.$store.dispatch("SetLoginWallet");
    },
    async selectInterNetwork(networkId) {
      // 选择登录网络
      const network = this.networkList.filter(
        (item) => item.chainId === networkId
      );
      if (network && network.length > 0) {
        // 设置网络
        // 设置网络名 (如果有这个网络, 就切换, 没有这个网络, 就添加)
        // 设置provider (如果有插件, 就用插件, 没有就用node)
        // 设置插件的名称
        this.setNetwork(network[0]);
        this.selectNetworkDialog = false;
      }
    },
    setNetwork(network) {
      // 切换网络

      this.$store.dispatch("SetLoginNetwork", toRaw(network)).then(() => {
        localStorage.setItem(
          fatsaleNetworkCacheKey,
          JSON.stringify(toRaw(network))
        );
        if (window.ethereum) {
          this.connectWallet();
        }
      });
    },
    async showSelectNetworkDialog() {
      this.selectNetworkDialog = true;
    },
    getShortAddr(address) {
      if (address.length >= 42) {
        return address.slice(0, 4) + "..." + address.slice(address.length - 5);
      }
      return address;
    },
  },
};
</script>
<style lang="scss" scoped>
.top-btn {
  margin-right: 10px;
}

.chain-logo-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chain-logo {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.network-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.danger-btn {
  color: red;
  border-color: red;
}
.select-network-btn {
  display: flex;
  flex-direction: row;
  &-text {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
  }
}
</style>