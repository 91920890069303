/**
=========================================================
* Vue Soft UI Dashboard PRO - v3.0.0
=========================================================
* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
Coded by www.creative-tim.com
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import VueTilt from "vue-tilt.js";
import VueSweetalert2 from "vue-sweetalert2";
import SoftUIDashboard from "./soft-ui-dashboard";
import ElementUI from "element-plus"
// import VueClipboard from 'vue3-clipboard'
import VueClipboard from 'vue-clipboard2'
import bus from 'vue3-eventbus'
// import Cropper from "vue3-cropper";
// import 'vue3-cropper/lib/vue3-cropper.css';
import 'ant-design-vue/dist/antd.css';


import "element-plus/theme-chalk/index.css"

const appInstance = createApp(App);
// appInstance.use(Cropper)
appInstance.use(bus)
appInstance.use(VueClipboard)
appInstance.use(ElementUI)
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(SoftUIDashboard);
appInstance.mount("#app");
