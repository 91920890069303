
// // worker池, 同时启动多个worker
// class WorkerPool {
//     workers: [],
//     newWorker: (workerPath, workerCount) => {
//         // 传入worker的加载路径, 传入要创建的workers数量
//         console.log(111)
// }
// }

export function WorkerPool(workerPath, workerCount) {
    this.workers = []
    this.totalLength = [] // 总加载长度
    this.nowLength = [] // 当前加载长度
    this.finishedCount = 0 // 当这个长度等于worker数量时, 全部初始化完成
    this.initTimer = null // 初始化使用的timer
    this.scanTotal = [] // 扫描总数
    this.scanStart = 0
    this.scanEnd = 0
    this.scanTimer = null
    this.scanResult = null

    for (let i = 0; i < workerCount; i++) {
        this.workers.push(new Worker(workerPath))
    }

    // 初始化速度
    this.init = (wasmPath, progressCallback, finishedCallback) => {
        console.log('初始化过程')
        this.initTimer = null
        this.finishedCount = 0

        // 初始化所有worker
        this.workers.forEach((worker, workerInd) => {
            this.nowLength[workerInd] = 0
            this.totalLength[workerInd] = 0
            if (!worker) return
            worker.addEventListener('message', ({ data }) => {
                const { eventType, eventData } = data
                // 接收到进度添加到对应的进度中
                // 接收到
                // 接收到
                if (eventType === 'INITIALISED') {
                    this.finishedCount += 1
                }
                if (eventType === 'INITIALISE_PROGRESS') {
                    this.nowLength[workerInd] = parseInt(eventData[0])
                    this.totalLength[workerInd] = parseInt(eventData[1])
                }
            })

            worker.postMessage({
                eventType: 'INITIALISE',
                eventData: wasmPath
                // eventData: 'https://token-monitor.s3.amazonaws.com/test_12_01_12_22.wasm', // 支持fsc链的wasm
            })

        })

        // 一个定时器, 每200毫秒, 通知一次进度直到所有worker都加载完成
        this.initTimer = setInterval(() => {
            if (this.finishedCount >= this.workers.length) {
                this.initTimer && clearInterval(this.initTimer)
                finishedCallback()
                return
            }
            let t1 = 0
            let t2 = 0
            this.nowLength.forEach(item => {
                t1 += item
            })
            this.totalLength.forEach(item => {
                t2 += item
            })
            progressCallback(t1, t2)
        }, 200);

        // const finishedTimer = () => {
        //     if (this.finishedCount >= this.workers.length) {
        //         clearInterval(initTimer)
        //         finishedCallback()
        //         return true
        //     }
        //     setTimeout(() => {
        //         finishedTimer()
        //     }, 300);
        //     return false
        // }
        // setTimeout(finishedTimer, 300);

    }

    this.ContractGenerator = (args, scanTotalCallback, successCallback) => {
        this.scanTotal = []
        this.scanResult = null
        this.workers.forEach((worker, workerInd) => {
            this.scanTotal[workerInd] = 0
            if (!worker) return
            worker.addEventListener('message', ({ data }) => {
                // 接收进度数据和完成数据
                const { eventType, eventData } = data
                // 接收到进度添加到对应的进度中
                // 接收到
                // 接收到
                if (eventType === 'UpdateTotalScanAddressCount') {
                    this.scanTotal[workerInd] = parseInt(eventData) // 总数
                }
                if (eventType === 'ContractGeneratorFinished') {
                    this.scanResult = eventData
                }
            })

            worker.postMessage({
                eventType: 'ContractGenerator',
                eventData: [
                    ...args,
                    workerInd // 把worker索引传进去作为随机数的seed
                ]
            })
        })

        this.scanTimer = setInterval(() => {
            if (this.scanResult !== null) {
                this.scanTimer && clearInterval(this.scanTimer)
                successCallback(this.scanResult)
                return
            }
            let t = 0
            this.scanTotal.forEach(item => {
                t += item
            })
            scanTotalCallback(t)
        }, 200);
    }

    this.WalletGenerator = (args, scanTotalCallback, successCallback) => {
        this.scanTotal = []
        this.scanResult = null
        this.workers.forEach((worker, workerInd) => {
            this.scanTotal[workerInd] = 0
            if (!worker) return
            worker.addEventListener('message', ({ data }) => {
                // 接收进度数据和完成数据
                const { eventType, eventData } = data
                // 接收到进度添加到对应的进度中
                // 接收到
                // 接收到
                if (eventType === 'UpdateTotalScanAddressCount') {
                    this.scanTotal[workerInd] = parseInt(eventData) // 总数
                }
                if (eventType === 'WalletGeneratorFinished') {
                    this.scanResult = eventData
                }
            })

            worker.postMessage({
                eventType: 'WalletGenerator',
                eventData: args
            })
        })

        this.scanTimer = setInterval(() => {
            if (this.scanResult !== null) {
                this.scanTimer && clearInterval(this.scanTimer)
                successCallback(this.scanResult)
                return
            }
            let t = 0
            this.scanTotal.forEach(item => {
                t += item
            })
            scanTotalCallback(t)
        }, 200);
    }

    this.Log = (args) => {
        if (this.workers.length === 0) {
            return
        }

        this.workers[0].postMessage({
            eventType: 'Log',
            eventData: args
        })
    }
    this.ContractEoaGenerator = (args, scanTotalCallback, successCallback) => {
        this.scanTotal = []
        this.scanResult = null
        this.workers.forEach((worker, workerInd) => {
            this.scanTotal[workerInd] = 0
            if (!worker) return
            worker.addEventListener('message', ({ data }) => {
                // 接收进度数据和完成数据
                const { eventType, eventData } = data
                // 接收到进度添加到对应的进度中
                // 接收到
                // 接收到
                if (eventType === 'UpdateTotalScanAddressCount') {
                    this.scanTotal[workerInd] = parseInt(eventData) // 总数
                }
                if (eventType === 'ContractEoaGeneratorFinished') {
                    this.scanResult = eventData
                }
            })

            worker.postMessage({
                eventType: 'ContractEoaGenerator',
                eventData: args
            })
        })

        this.scanTimer = setInterval(() => {
            if (this.scanResult !== null) {
                this.scanTimer && clearInterval(this.scanTimer)
                successCallback(this.scanResult)
                return
            }
            let t = 0
            this.scanTotal.forEach(item => {
                t += item
            })
            scanTotalCallback(t)
        }, 200);
    }

    this.CreateWallets = (args, successCallback) => {
        this.workers[0].addEventListener('message', ({ data }) => {
            const { eventType, eventData } = data
            if (eventType === 'CreateWalletsFinished') {
                const list = eventData.split('|')
                const r = list.map(item => {
                    const x = item.split(',')
                    return {
                        publicKey: x[0],
                        privateKey: x[1]
                    }
                })
                successCallback(r)
            }

        })
        this.workers[0].postMessage({
            eventType: 'CreateWallets',
            eventData: args
        })



    }

    this.pingRpc = (rpc => {
        return new Promise((resolve, reject) => {
            this.workers[0].addEventListener('message', ({ data }) => {
                const { eventType, eventData } = data
                if (eventType === 'PingNodeResult') {
                    resolve(eventData)
                }
                if (eventType === 'PingNodeResultErr') {
                    reject(eventData)
                }
            })
            this.workers[0].postMessage({
                eventType: 'PingNode',
                eventData: rpc
            })
        })
    })

    this.startPresaleLogic = (args, cb, notifyCallback) => {
        // args
        return new Promise((resolve, reject) => {
            this.workers[0].addEventListener('message', ({ data }) => {
                const { eventType, eventData } = data
                if (eventType === 'PresaleLogicResult') {
                    resolve()
                }
                if (eventType === 'PresaleLogicCountdown') {
                    cb(eventData)
                }
                if (eventType === 'PresaleLogicNotify') {
                    notifyCallback(eventData)
                }
                if (eventType === 'PresaleLogicErr') {
                    reject(eventData)
                }
            })
            this.workers[0].postMessage({
                eventType: 'PresaleLogic',
                eventData: args
            })
        })
    }

    this.stop = () => {
        // 停止所有worker
        this.scanTimer && clearInterval(this.scanTimer)
        this.initTimer && clearInterval(this.initTimer)
        this.workers.forEach(worker => {
            worker && worker.terminate()
        })
    }

}
