<template>
  <div>
    <div class="row">
      <div class="col-12 upload-box">
        <el-upload
          :on-success="handleUploadSuccess"
          :on-progress="handleUploadProgress"
          :http-request="handleUpload"
          :show-file-list="false"
        >
          <img
            v-if="uploadImg"
            :src="uploadImg"
            class="avatar upload-img-ave"
          />
          <el-icon v-else class="avatar-uploader-icon"
            ><Plus v-if="!uploading" />
            <span v-if="uploading"> {{ uploadPercent }}% </span>
          </el-icon>
        </el-upload>
        <div style="margin-top: 10px">上传图片</div>
        <div style="margin-top: 10px; width: 70%">
          <input
            v-model="uploadImg"
            type="text"
            disabled
            class="form-control"
            placeholder="图片链接"
          />
        </div>
        <div style="margin-top: 10px">
          <button
            type="button"
            name="button"
            class="m-0 btn bg-gradient-info ms-2"
            @click="copyUrl"
          >
            复制
          </button>
        </div>
        <div style="margin-top: 10px; font-size: 12px">
          <a
            style="margin-top: 10px"
            target="_blank"
            href="https://tinypng.com/"
            >图片压缩工具</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { AwsUpdate } from "@/utils/config";
import { ElUpload, ElIcon } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
export default {
  components: {
    ElUpload,
    ElIcon,
    Plus,
  },
  data() {
    return {
      uploading: false,
      uploadImg: "",
      uploadPercent: 0,
    };
  },
  methods: {
    copyUrl() {
      this.$copyText(this.uploadImg);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    handleUploadProgress(file) {
      console.log(file);
    },

    handleUploadSuccess(file) {
      console.log(file);
    },
    async handleUpload(file) {
      console.log(file);
      let k = Date.now() + "" + file.file.name;
      this.uploading = true;

      this.$nextTick(() => {
        AwsUpdate(file.file, k, (x) => {
          const percent = Math.round((x.loaded / x.total) * 10000) / 100;
          console.log("上传进度是", percent, "%");
          this.uploadPercent = percent;
        }).then((x) => {
          console.log("上传之后的结果是", x);
          this.uploadImg = x.Location;
          this.uploading = false;
        });
      });
    },
    handleSelectChain() {},
  },
};
</script>
<style lang="scss" scoped>
.upload-img-ave {
  width: 200px;
  height: 200px;
}
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  text-align: center;
  border: 1px dashed #8c939d;
}
.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>