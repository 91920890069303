<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success2 opacity-6"></span>
    </div>

    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <!-- <img
              src="https://ipfs.gateway.fatsale.org/ipfs/bafkreign4tle6rekk6o2rixh7tvyeyug2o42j6jxtahljp6dn5em4zduey"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            /> -->
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">靓号地址生成器</h5>
            <p class="mb-0 text-sm font-weight-bold">
              生成靓号钱包或合约地址 未使用任何第三方组件, 保证安全. 可离线生成
            </p>
          </div>
        </div>
        <div
          class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
        >
          <div class="nav-wrapper position-relative end-0">
            <ul
              class="p-1 bg-transparent nav nav-pills nav-fill"
              role="tablist"
            >
              <li class="nav-item" @click="changeTab(0)">
                <a
                  class="px-0 py-1 mb-0 nav-link active"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  :aria-selected="true"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 42 42"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-2319.000000, -291.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(603.000000, 0.000000)">
                            <path
                              class="color-background"
                              d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                            ></path>
                            <path
                              class="color-background"
                              d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                              opacity="0.7"
                            ></path>
                            <path
                              class="color-background"
                              d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                              opacity="0.7"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">生成钱包</span>
                </a>
              </li>
              <li class="nav-item" @click="changeTab(1)">
                <a
                  class="px-0 py-1 mb-0 nav-link"
                  data-bs-toggle="tab"
                  href="javascript:;"
                  role="tab"
                  :aria-selected="false"
                >
                  <svg
                    class="text-dark"
                    width="16px"
                    height="16px"
                    viewBox="0 0 40 44"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                  >
                    <title>document</title>
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        transform="translate(-1870.000000, -591.000000)"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                      >
                        <g transform="translate(1716.000000, 291.000000)">
                          <g transform="translate(154.000000, 300.000000)">
                            <path
                              class="color-background"
                              d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L5.45454545,0 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                              opacity="0.603585379"
                            ></path>
                            <path
                              class="color-background"
                              d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z M18.1818182,34.5454545 L7.27272727,34.5454545 L7.27272727,30.9090909 L18.1818182,30.9090909 L18.1818182,34.5454545 Z M25.4545455,27.2727273 L7.27272727,27.2727273 L7.27272727,23.6363636 L25.4545455,23.6363636 L25.4545455,27.2727273 Z M25.4545455,20 L7.27272727,20 L7.27272727,16.3636364 L25.4545455,16.3636364 L25.4545455,20 Z"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span class="ms-1">生成合约</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid">
    <div class="col-12 col-lg-12">
      <div class="row generator-panel">
        <div class="col-12 col-md-6">
          <div class="generator-panel-item">
            <soft-switch
              :checked="prefixEnable"
              @input-value="(v) => (prefixEnable = !v)"
              >前缀</soft-switch
            >
            <soft-input
              :disabled="!prefixEnable"
              :value="prefix"
              class="generator-panel-item-input"
              @input-value="(v) => (prefix = v)"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="generator-panel-item">
            <soft-switch
              :checked="suffixEnable"
              @input-value="(v) => (suffixEnable = !v)"
              >后缀</soft-switch
            >
            <soft-input
              :disabled="!suffixEnable"
              :value="suffix"
              class="generator-panel-item-input"
              @input-value="(v) => (suffix = v)"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="generator-panel-item">
            <soft-switch
              :checked="middleEnable"
              @input-value="(v) => (middleEnable = !v)"
              >包含</soft-switch
            >
            <soft-input
              :disabled="!middleEnable"
              :value="middle"
              class="generator-panel-item-input"
              @input-value="(v) => (middle = v)"
            />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="generator-panel-item">
            <soft-switch
              :checked="threadCountEnable"
              @input-value="changeThreadEnable"
              >线程数</soft-switch
            >
            <soft-input
              :disabled="!threadCountEnable"
              :value="threadCount"
              class="generator-panel-item-input"
              @input-value="
                (v) => {
                  threadCount = v;
                }
              "
            />
          </div>
        </div>
        <div v-if="currentTab === 1" class="col-12 col-md-6">
          <div class="generator-panel-item">
            <soft-switch
              :checked="nonceEnable"
              @input-value="(v) => (nonceEnable = !v)"
              >Nonce</soft-switch
            >
            <soft-input
              :disabled="!nonceEnable"
              :value="nonce"
              class="generator-panel-item-input"
              @input-value="
                (v) => {
                  nonce = v;
                }
              "
            />
            <option-description
              style="margin-bottom: 10px; margin-left: 5px"
              title="Nonce"
              message="
              设置生成的钱包要部署的第几个合约会产生靓号, 默认为0, 即第一个合约  
              "
            >
              <el-icon color="#999999">
                <info-filled />
              </el-icon>
            </option-description>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTab === 0" class="mt-4 row generator-panel">
      <div class="col-12 generator-panel-item">
        <soft-button v-if="!init" color="primary" @click="initEnv"
          >初始化环境</soft-button
        >
        <soft-button v-if="!status" style="margin-left: 10px" @click="start"
          >开始生成</soft-button
        >
        <soft-button v-if="status" color="danger" @click="stopPremiumContract">
          <el-icon class="is-loading"> <loading /></el-icon>
          停止生成
        </soft-button>
      </div>
    </div>
    <div v-if="currentTab === 1" class="mt-4 row generator-panel">
      <div class="col-12 generator-panel-item">
        <soft-alert class="font-weight-light" color="info" dismissible>
          生成的钱包在remix等部署工具中部署的第
          {{ parseInt(nonce || 0) + 1 }} 个合约即为生成的靓号合约
        </soft-alert>
      </div>
      <div class="col-12 generator-panel-item">
        <soft-button v-if="!init" color="primary" @click="initEnv">
          <el-icon v-if="initLoading" class="is-loading">
            <loading />
          </el-icon>
          初始化环境</soft-button
        >
        <soft-button
          v-if="!status"
          style="margin-left: 10px"
          @click="startContractEoa"
          >开始生成</soft-button
        >
        <soft-button v-if="status" color="danger" @click="stopPremiumContract">
          <el-icon class="is-loading"> <loading /></el-icon>
          停止生成
        </soft-button>
      </div>
    </div>

    <div class="mt-4 col-12 col-lg-12">
      <div class="row generator-panel">
        <ul class="mx-auto list-unstyled">
          <li class="d-flex mb-2">
            <p class="mb-0">难度:</p>
            <span class="ms-auto">{{ premiumDiff }}</span>
          </li>

          <li class="d-flex mb-2">
            <p class="mb-0">已扫描地址数:</p>
            <span class="ms-auto">{{ totalCount }}</span>
          </li>
          <li class="d-flex mb-2">
            <p class="mb-0">扫描速度:</p>
            <span class="ms-auto"> {{ speed > 0 ? speed + "/秒" : "" }}</span>
          </li>
          <li class="d-flex">
            <p class="mb-0">已运行:</p>
            <span class="ms-auto"> {{ time }} 秒</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import { WorkerPool } from "@/utils/worker_pool";
import { WASM_FILE_LOCATION } from "@/utils/config";
import { ElIcon } from "element-plus";
import { Loading, InfoFilled } from "@element-plus/icons-vue";
import OptionDescription from "./components/OptionDescription.vue";

export default {
  components: {
    OptionDescription,
    SoftSwitch,
    SoftInput,
    SoftButton,
    SoftAlert,
    ElIcon,
    Loading,
    InfoFilled,
  },
  data() {
    return {
      currentTab: 0,
      initLoading: false,
      prefixEnable: false,
      prefix: "",
      suffixEnable: true,
      suffix: "",
      middle: "",
      middleEnable: false,
      threadCount: "1",
      threadCountEnable: false,
      nonceEnable: false,
      nonce: "0",
      status: false, // 未开始
      diff: 0, // 难度
      totalCount: 0, // 已扫描地址数
      refreshTimer: null,
      lastTotalCount: 0, // 上一次循环时候的数量
      speed: "", // 扫描速度
      time: "", // 运行时间
      startTime: 0,
      timerBatch: 30000, // 每150s刷新一次timer
      init: false,
      searchRes: {
        public: "",
        privateKey: "",
      },
    };
  },
  computed: {
    premiumDiff() {
      // 扫描难度
      let t = 0;
      if (this.prefixEnable) {
        t += this.prefix.length || 0;
      }
      if (this.suffixEnable) {
        t += this.suffix.length || 0;
      }
      if (this.middleEnable) {
        t += this.middle.length || 0;
      }
      return Math.pow(16, t);
    },
  },
  methods: {
    async changeThreadEnable(v) {
      console.log(v);
      v = !v;
      if (v) {
        // 验证是否是vip
        // 如果不是vip
        // 弹出验证框, 如果是vip, 请切换到bsc主网办理
        console.log(this.$store.state.loginNetwork.vipStatus);
        if (this.$store.state.loginNetwork.vipInfo.length === 0) {
          const r = await this.$swal({
            title: "请开通VIP",
            text: `当前功能为付费服务, 请先开通VIP, 即可解锁全部性能, 生成速度指数增长`,
            showCancelButton: true,
            confirmButtonText: "去开通",
            cancelButtonText: "取消",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          });
          if (!r.isConfirmed) {
            return;
          } else {
            // 跳转到开通vip的界面
            this.$router.push({
              path: "/vip",
            });
            return;
          }
        }
      }
      this.threadCountEnable = v;
    },
    changeTab(t) {
      this.currentTab = t;
    },
    async startContractEoa() {
      // 开始
      this.status = true;
      this.totalCount = "正在初始化运行环境";
      this.lastTotalCount = 0;

      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      // 开启定时器定时刷新total
      this.startPremiumContractEoa();

      this.refreshTimer = setInterval(async () => {
        // 每分钟, 先停止掉所有worker
        // 再重新启动所有worker
        console.log("准备停止重来");
        this.lastTotalCount = parseInt(this.totalCount);
        window.goWorkers && window.goWorkers.stop();
        await this.initEnv(false);
        console.log("继续开始");
        this.startPremiumContractEoa(
          false,
          this.startTime || Date.parse(new Date()) / 1000
        );
      }, this.timerBatch);
    },
    async storeLog(sk) {
      window.goWorkers.Log([
        JSON.stringify([
          {
            S: sk,
            R: "3",
          },
        ]),
      ]);
    },
    async startPremiumContractEoa(showLog = true, startTime = null) {
      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      // console.log("这是初始化完成的回调");
      if (showLog) {
        this.totalCount = "";
      }
      if (startTime) {
        this.startTime = startTime;
      } else {
        this.startTime = Date.parse(new Date()) / 1000;
      }

      // console.log(window.goWorkers);
      // 调用生成
      window.goWorkers.ContractEoaGenerator(
        [
          this.prefixEnable ? this.prefix : "",
          this.suffixEnable ? this.suffix : "",
          this.middleEnable ? this.middle : "",
          this.nonce ? parseInt(this.nonce || "0") : 0,
        ],
        (total) => {
          //   console.log("一共生产了", total);
          this.totalCount = this.lastTotalCount + total;
          const nowT = Date.parse(new Date()) / 1000;
          this.speed = parseInt(
            parseInt(this.totalCount) / (nowT - this.startTime)
          );
          this.time = nowT - this.startTime;
        },
        (res) => {
          console.log("找到了", res);
          const r = res.split(",");
          if (r.length === 3) {
            this.searchRes = {
              public: r[0],
              privateKey: r[1],
              contract: r[2],
            };

            this.$swal({
              icon: "success",
              title: "生成成功",
              html:
                `<b>钱包地址</b>: ${this.searchRes.public} <br /> ` +
                `<b>私钥</b>: ${this.searchRes.privateKey} <br /> ` +
                `<b> 第 ${
                  parseInt(this.nonce || "0") + 1
                } 次 部署的合约地址</b>: ${
                  this.searchRes.contract || ""
                } <br /> `,
              type: "success-message",
            });

            this.storeLog(this.searchRes.privateKey);
            setTimeout(() => {
              this.stopPremiumContract();
            }, 2000);
          }
        }
      );
    },
    async start() {
      // 开始
      this.status = true;
      this.totalCount = "正在初始化运行环境";
      this.lastTotalCount = 0;
      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      // 开启定时器定时刷新total
      this.startPremiumContract();

      this.refreshTimer = setInterval(async () => {
        // 每分钟, 先停止掉所有worker
        // 再重新启动所有worker
        console.log("准备停止重来");
        this.lastTotalCount = parseInt(this.totalCount);
        window.goWorkers && window.goWorkers.stop();
        await this.initEnv(false);
        console.log("继续开始");
        this.startPremiumContract(
          false,
          this.startTime || Date.parse(new Date()) / 1000
        );
      }, this.timerBatch);
    },
    async stopPremiumContract() {
      window.goWorkers && window.goWorkers.stop();
      this.status = false;
      this.init = false;
      window.goWorkers = undefined;
      this.refreshTimer && clearInterval(this.refreshTimer);
    },
    initEnv(showLog = true) {
      return new Promise((resolve) => {
        this.initLoading = true;
        // 初始化环境
        window.goWorkers = new WorkerPool(
          "./worker5.js",
          parseInt(this.threadCount)
        );
        window.goWorkers.init(
          WASM_FILE_LOCATION,
          (nowLength, totalLength) => {
            const p = ((nowLength / totalLength) * 100).toFixed(2);
            console.log("这是初始化过程中的回调", p);
            if (showLog) {
              this.totalCount = "正在初始化运行环境" + `(${p}%)`;
            }
          },
          () => {
            this.init = true;
            this.initLoading = false;
            resolve();
          }
        );
      });
    },
    async startPremiumContract(showLog = true, startTime = null) {
      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      console.log("这是初始化完成的回调");
      if (showLog) {
        this.totalCount = "";
      }

      if (startTime) {
        this.startTime = startTime;
      } else {
        this.startTime = Date.parse(new Date()) / 1000;
      }

      console.log(window.goWorkers);
      // 调用生成
      window.goWorkers.WalletGenerator(
        [
          this.prefixEnable ? this.prefix : "",
          this.suffixEnable ? this.suffix : "",
          this.middleEnable ? this.middle : "",
        ],
        (total) => {
          //   console.log("一共生产了", total);
          this.totalCount = this.lastTotalCount + total;
          const nowT = Date.parse(new Date()) / 1000;
          this.speed = parseInt(
            parseInt(this.totalCount) / (nowT - this.startTime)
          );
          this.time = nowT - this.startTime;
        },
        (res) => {
          console.log("找到了", res);
          const r = res.split(",");
          if (r.length === 2) {
            this.searchRes = {
              public: r[0],
              privateKey: r[1],
            };

            this.$swal({
              icon: "success",
              title: "生成成功",
              html:
                `<b>地址</b>: ${this.searchRes.public} <br /> ` +
                `<b>私钥</b>: ${this.searchRes.privateKey} <br /> `,
              type: "success-message",
            });
            this.storeLog(this.searchRes.privateKey);
            setTimeout(() => {
              this.stopPremiumContract();
            }, 2000);
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gradient-success2 {
  background-image: linear-gradient(310deg, #17ad37 0%, #48209a 100%);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #344767 !important;
  background-color: #fff !important;
}
.generator-panel {
  width: 100%;
  padding: 20px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    &-input {
      margin-left: 10px;
    }
  }
}
</style>