import axios from "axios"
// import { getCurrentUserToken } from "./login"

const baseUrl = "https://x.tmdex.org"
// const baseUrl = "http://127.0.0.1:8090"

export const getItem = (key) => {
    if (window._tm_) {
        return prompt(`tmd://get_file?name=${key}`)
    }
    // 获取item
    return window.localStorage.getItem(key)
}

// const current_user_key = 'local_current_user'
const current_user_token = 'local_current_user_token'


export const setItem = (key, v) => {
    if (window._tm_) {
        // prompt(`tmd://put_file?name=${key}&content=${v}`)
        window._tm_.setToFile(key, v);
        return
    }
    window.localStorage.setItem(key, v)
}


export const setCurrentUserToken = (token) => {
    setItem(current_user_token, token)
}


function getCurrentUserToken() {
    return getItem(current_user_token)
}

const Network = {
    request(p) {
        const token = getCurrentUserToken()
        if (p["url"].indexOf(baseUrl) === -1) {
            p["url"] = baseUrl + p["url"]
        }

        if (token) {
            p.headers = {
                ...p?.headers,
                Authorization: 'Bearer ' + token
            }
        }


        return new Promise((resolve, reject) => {
            // 对接app的时候,这里改成调用app的方法
            axios(p).then(response => {
                resolve(response)
            }).catch(e => {
                reject(e)
            })

        })
    }
}

export default Network