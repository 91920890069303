<template>
  <span class="info" @click.stop="showMessage">
    <slot />
  </span>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    showMessage() {
      this.$swal({
        title: this.title,
        text: this.message,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>