<template>
  <div @click="select" :class="'network-item ' + (active ? 'network-item-active' : '')">
    <div v-if="avatar">
      <img :src="avatar" class="network-item-avatar" alt="" />
    </div>
    <div class="network-item-name">{{ name }}</div>
  </div>
</template>
<script>
export default {
  props: {
    logo: {
      type: String,
      default: '',
    },
    chainId: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatar: '',
      name: '',
      active: false,
      networkId: 0,
    }
  },
  watch: {
    logo(v) {
      this.avatar = v
    },
    text(v) {
      this.name = v
    },
    isActive(v) {
      this.active = v
    },
    chainId(v) {
      this.networkId = v
    },
  },
  mounted() {
    this.avatar = this.logo
    this.name = this.text
    this.active = this.isActive
    this.networkId = this.chainId
  },
  methods: {
    select() {
      this.$emit('select', this.networkId)
    },
  },
}
</script>
<style lang="scss" scoped>
.network-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 130px;
  padding: 15px;
  margin: 10px;
  cursor: pointer;

  border-radius: 10px;
  &-active {
    background-color: #eeeeee;
  }
  &-name {
    font-size: 12px;
    margin-top: 10px;
  }
  &-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}
</style>