<template>
  <div class="card bg-transparent shadow-xl">
    <div
      class="overflow-hidden position-relative border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
      }"
    >
      <span class="mask" :class="`bg-gradient-${card.background}`"></span>
      <div class="card-body position-relative z-index-1 p-3">
        <!-- <i class="fas fa-wifi text-white p-2" aria-hidden="true"></i> -->
        <span class="text-white p-2">当前VIP版本</span>
        <h5 class="text-white mt-4 mb-5 pb-2">
          <!-- {{ currentNetwork.currentWallet || "" }} -->
          <div
            v-for="(item, ind) in currentNetwork.vipInfo"
            :key="item.expiredAt + 'aaa'"
          >
            {{ getPlanName(ind) }}
          </div>
        </h5>
        <!-- <div class="d-flex">
          <div class="d-flex">
            <div
              v-if="currentNetwork.vipInfo && currentNetwork.vipInfo.length > 0"
            >
              <p class="text-white text-sm opacity-8 mb-0">当前VIP版本</p>
              <h6 class="text-white mb-0">
               
              </h6>
            </div>
          </div>
        </div> -->
        <div class="d-flex">
          <div class="d-flex">
            <div
              v-if="
                currentNetwork.vipInviter &&
                currentNetwork.vipInviter !==
                  '0x0000000000000000000000000000000000000000'
              "
              :class="$store.state.isRTL ? 'ms-4' : 'me-4'"
            >
              <p class="text-white text-sm opacity-8 mb-0">邀请人</p>
              <h6 class="text-white mb-0">
                {{ smallAddr(currentNetwork.vipInviter) }}
                <el-icon @click="copyAddress(currentNetwork.vipInviter)">
                  <copy-document />
                </el-icon>
              </h6>
            </div>

            <div
              v-if="currentNetwork.vipInfo && currentNetwork.vipInfo.length > 0"
            >
              <p class="text-white text-sm opacity-8 mb-0">过期时间</p>
              <h6 class="text-white mb-0">
                <div
                  v-for="item in currentNetwork.vipInfo"
                  :key="item.expiredAt"
                >
                  {{ getTimeStr(item) }}
                </div>
              </h6>
            </div>
            <div
              v-if="
                !currentNetwork.vipInfo || currentNetwork.vipInfo.length === 0
              "
            >
              <p class="text-white text-sm opacity-8 mb-0">当前未开通VIP</p>
              <!-- <h6 class="text-white mb-0">当前未开通VIP</h6> -->
            </div>
          </div>
          <div
            class="w-20 d-flex align-items-end justify-content-end"
            :class="$store.state.isRTL ? 'me-auto' : 'ms-auto'"
          >
            <!-- <soft-avatar class="w-60 mt-2" :img="img1" alt="logo" /> -->

            <soft-button size="md" @click="copyInviteLink">
              邀请链接
            </soft-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftButton from "@/components/SoftButton.vue";
import img from "../../assets/img/curved-images/curved14.jpg";
import img1 from "../../assets/img/logos/mastercard.png";
import { ElIcon } from "element-plus";
import { CopyDocument } from "@element-plus/icons-vue";
export default {
  name: "MasterCard",
  components: {
    SoftButton,
    ElIcon,
    CopyDocument,
    SoftAvatar,
  },
  props: {
    card: {
      type: Object,
      number: String,
      holderName: String,
      expiryDate: String,
      holderText: String,
      expiryText: String,
      background: String,
      default: () => ({
        // number: this.currentNetwork ? this.currentNetwork.currentWallet : "",
        holderName: "Jack Peterson",
        expiryDate: "11/22",
        holderText: "Card Holder",
        expiryText: "Expires",
        background: "dark",
      }),
    },
  },
  data() {
    return {
      img,
      img1,
    };
  },
  computed: {
    currentNetwork() {
      return this.$store.state.loginNetwork;
    },
  },

  methods: {
    smallAddr(addr) {
      return addr.slice(0, 3) + "..." + addr.slice(-4);
    },
    getPlanName(planIndex) {
      if (planIndex === 0) return "开发者版按天试用";
      if (planIndex === 1) return "开发者版按周付费";
    },
    async copyAddress(address) {
      await this.$copyText(address);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async copyInviteLink() {
      const link = `欢迎加入TokenMonitor VIP, 可使用靓号合约批量空投等工具 https://tool.fatsale.org/#/vip?inviter=${this.currentNetwork.currentWallet}`;
      await this.$copyText(link);
      // console.log("已部署");
      this.$swal({
        title: "已复制邀请链接",
        html: "邀请返利规则: <br> 每邀请一位用户成为VIP, 您可获得他付费价格的20% <br> 您的下级每邀请一位用户成为VIP, 您可获得他付费价格的5%",
      });
    },
    getTimeStr(timestamp) {
      const now = new Date(parseInt(timestamp) * 1000);

      var year = now.getFullYear(),
        month = now.getMonth() + 1,
        date = now.getDate(),
        hour = now.getHours(),
        minute = now.getMinutes();
      // second = now.getSeconds();

      return year + "-" + month + "-" + date + " " + hour + ":" + minute;
    },
  },
};
</script>
