import Network from "../network"

export const sendEmailCode = (data) => {
    return Network.request({
        url: "/api/2.0/send-code",
        method: "post",
        data
    })
}


export const appRegister = (data) => {
    return Network.request({
        url: "/api/2.0/register",
        method: "post",
        data
    })
}

export const appLogin = (data) => {
    return Network.request({
        url: "/api/2.0/login",
        method: "post",
        data
    })
}


export const getUserProfile = () => {
    return Network.request({
        url: "/api/2.0/user/profile",
        method: "get",
    })
}

export const submitTokenLogo = (data) => {
    return Network.request({
        url: "/api/2.0/token-info/submit",
        method: "post",
        data
    })
}