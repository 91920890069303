<template>
  <div class="container-fluid"></div>
  <div class="py-4 container-fluid">
    <div>
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="mx-auto col-lg-9 col-12">
            <div class="mt-4 card card-body">
              <h6 class="mb-0">
                申请收录
                <span style="font-size: 12px">
                  <a
                    style="color: #cb0c9f"
                    target="blank"
                    href="https://www.youtube.com/shorts/MbvLGzApdP0"
                    >视频教程</a
                  >
                </span>
              </h6>

              <p class="mb-0 text-sm">
                <!-- 填写表单后通过申请, 即可收录到Token Monitor中 -->
              </p>
              <hr class="my-3 horizontal dark" />
              <div class="row mt-3">
                <div class="col-12 col-sm-6">
                  <label>选择主网</label>
                  <select
                    id="choices-gender"
                    v-model="form.chainId"
                    class="form-control"
                  >
                    <option
                      v-for="item in networks"
                      :key="item.chainId"
                      :value="item.chainId"
                    >
                      {{ item.name }}
                    </option>
                    <!-- <option value="-1">自定义公链</option> -->
                  </select>
                </div>
                <div class="col-12 col-sm-6 mt-3 mt-sm-0">
                  <label>合约地址</label>
                  <soft-input
                    :value="form.contract"
                    class="multisteps-form__input"
                    type="text"
                    placeholder="0x..."
                    @input-value="changeContract"
                  />
                </div>
              </div>
              <div v-if="form.contract" class="row">
                <soft-alert
                  v-if="!fromFatsale"
                  color="primary"
                  style="font-size: 12px"
                >
                  推荐使用
                  <a
                    style="color: #ffffff"
                    target="_blank"
                    href="https://fatsale.org/#/token/create"
                  >
                    <el-icon>
                      <connection />
                    </el-icon>
                    Fatsale</a
                  >
                  平台创建代币, 可享受平台补贴, 免费使用各类项目方工具,
                  上头像有优惠哦~
                </soft-alert>
                <soft-alert
                  v-if="fromFatsale"
                  color="success"
                  style="font-size: 12px"
                >
                  该代币通过
                  <a
                    style="color: #ffffff"
                    target="_blank"
                    href="https://fatsale.org/#/token/create"
                  >
                    <el-icon>
                      <connection />
                    </el-icon>
                    Fatsale</a
                  >
                  平台发射, 享有平台补贴~
                </soft-alert>
              </div>
              <div class="row">
                <div class="col-12">
                  <label class="form-label">上传LOGO (90*90) </label>
                  <el-upload
                    :on-success="handleUploadSuccess"
                    :on-progress="handleUploadProgress"
                    :http-request="handleUpload"
                    :show-file-list="false"
                  >
                    <img
                      v-if="uploadImg"
                      :src="uploadImg"
                      class="avatar upload-img-ave"
                    />
                    <el-icon v-else class="avatar-uploader-icon"
                      ><Plus v-if="!uploading" />
                      <span v-if="uploading"> {{ uploadPercent }}% </span>
                    </el-icon>
                  </el-upload>
                  <Modal
                    v-model:visible="showCropper"
                    title="图片裁切"
                    :mask-closable="false"
                  >
                    <div>
                      <vue-picture-cropper
                        v-if="showCropper"
                        :box-style="{
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#f8f8f8',
                          margin: 'auto',
                        }"
                        image-smoothing-quality="high"
                        :img="cropperPic"
                        :options="{
                          viewMode: 1,
                          dragMode: 'crop',
                          aspectRatio: 1 / 1,
                        }"
                        :preset-mode="{
                          mode: 'fixedSize',
                          width: 300,
                          height: 300,
                        }"
                        @ready="ready"
                      />
                    </div>
                    <template #footer>
                      <Button @click="showCropper = false">取消</Button>
                      <Button type="primary" @click="onSaveCropperImage"
                        >裁切</Button
                      >
                    </template>
                  </Modal>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <label class="form-label">Telegram </label>
                  <input
                    v-model="form.telegram"
                    type="text"
                    class="form-control"
                    placeholder="(选填)https://t.me/..."
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label class="form-label">Twitter </label>
                  <input
                    v-model="form.twitter"
                    type="text"
                    class="form-control"
                    placeholder="(选填)https://twitter.com/..."
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label class="form-label">官网 </label>
                  <input
                    v-model="form.website"
                    type="text"
                    class="form-control"
                    placeholder="(选填)https://"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label class="form-label">QQ群 </label>
                  <input
                    v-model="form.qq"
                    type="text"
                    class="form-control"
                    placeholder="(选填)"
                  />
                </div>
                <div class="col-md-6 col-sm-12">
                  <label class="form-label">Youtube </label>
                  <input
                    v-model="form.youtube"
                    type="text"
                    class="form-control"
                    placeholder="(选填)https://"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label class="form-label">简介 </label>
                  <soft-textarea
                    :value="form.intro"
                    placeholder="(选填)项目介绍, 500字以内"
                    @input-value="(v) => (form.intro = v)"
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-12">
                  <label class="form-label">其他 </label>
                  <soft-textarea
                    :value="form.other"
                    placeholder="(选填)有其他链接或附加信息, 可填写在此处"
                    @input-value="(v) => (form.other = v)"
                  />
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-12 col-sm-12">
                  <label class="form-label">联系邮箱 </label>
                  <input
                    v-model="form.email"
                    type="text"
                    class="form-control"
                    placeholder="@"
                  />
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-12">
                  <label class="form-label">付款方式 </label>
                  <div class="form-check">
                    <input
                      v-model="payType"
                      class="form-check-input"
                      type="radio"
                      :value="0"
                    />
                    <label class="custom-control-label">直接支付</label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="payType"
                      class="form-check-input"
                      type="radio"
                      :value="1"
                    /><label class="custom-control-label">填写转账哈希</label>
                  </div>
                </div>
              </div> -->
              <!-- <div v-if="payType === 0" class="row">
                <div class="col-12">
                  <label> 支付费用 </label>
                  <div v-if="!payHash">
                    <b>{{ payAmount }}</b> USDT
                    <button
                      type="button"
                      name="button"
                      class="m-0 btn bg-gradient-info ms-2"
                      @click="pay"
                    >
                      <el-icon v-if="payLoading" class="is-loading">
                        <loading />
                      </el-icon>
                      支付
                    </button>
                  </div>
                  <div v-if="payHash">
                    <soft-alert>
                      支付完成, 转账哈希为 {{ payHash }}
                      <el-icon @click="copyAddress(payHash)">
                        <copy-document /> </el-icon
                      >, 现在您可以提交收录申请了
                    </soft-alert>
                  </div>
                </div>
              </div>
              <div v-if="payType === 1" class="row">
                <div class="col-12">
                  <soft-alert color="info" size="small">
                    请使用BSC主网钱包, 向平台的收款钱包 {{ receivingWallet }}
                    <el-icon @click="copyAddress(receivingWallet)">
                      <copy-document />
                    </el-icon>
                    中转入
                    <b>{{ payAmount }} </b> USDT, 并将转账哈希填写在下面输入框中
                  </soft-alert>
                  <label> 输入转账哈希 </label>
                  <input v-model="form.hash" type="text" class="form-control" />
                </div>
              </div> -->

              <div class="mt-4 d-flex justify-content-end">
                <div v-if="form.contract" class="pay-text">
                  支付: {{ payAmount }} USDT
                </div>

                <a target="_blank" href="https://t.me/fatsaleOwner">
                  <button type="button" name="button" class="m-0 btn btn-light">
                    联系客服
                  </button></a
                >
                <button
                  type="button"
                  name="button"
                  class="m-0 btn bg-gradient-success ms-2"
                  @click="submit"
                >
                  <el-icon v-if="submitLoading" class="is-loading">
                    <loading />
                  </el-icon>
                  付款并提交
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftInput from "@/components/SoftInput.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import SoftRadio from "@/components/SoftRadio.vue";

import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill";
import {
  NETWORK_LIST,
  AwsUpdate,
  BSC_MAINNET,
  tokenAbi,
  // tokenDeployFactoryMap,
  // tokenDeployFactoryAbi,
  getPrividerWithChainId,
  getImageSize,
  getTokenFromFatsale,
  // CORE_MAINNET,
} from "@/utils/config";
import { ElUpload, ElIcon } from "element-plus";
import {
  Plus,
  CopyDocument,
  Loading,
  Connection,
} from "@element-plus/icons-vue";
import { BigNumber, ethers } from "ethers";
import { submitTokenLogo } from "@/utils/api/login";
import { toRaw } from "vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper"; // cropper
import { Button, Modal } from "ant-design-vue";

export default {
  components: {
    SoftInput,
    SoftTextarea,
    ElUpload,
    ElIcon,
    Plus,
    SoftRadio,
    SoftAlert,
    CopyDocument,
    Loading,
    Connection,
    VuePictureCropper,
    Modal,
    Button,
  },
  data() {
    return {
      payType: 0,
      currentTab: 0,
      showCropper: false,
      cropperImagePath: "",
      cropperPic: "", // 待裁剪的图片的base64信息
      showMenu: false,
      selectChainId: "",
      receivingWallet: "0x813AFc3311607299D356EAb75aDbb3bd34388888",
      usdtAddress: "0x55d398326f99059ff775485246999027b3197955",

      uploadImg: "",
      uploadPercent: 0,
      uploading: false,
      payLoading: false,
      submitLoading: false,
      tokenInfoScanStatus: false,
      payHash: "",
      form: {
        chainId: "",
        hash: "",
        mainnet: "", // 主网
        contract: "", // 合约地址
        name: "", // 代币名称
        symbol: "", // 符号
        launch_at: "", // 启动时间
        description: "", // 项目介绍
        logo: "", // logo地址
        website: "",
        qq: "",
        twitter: "",
        facebook: "",
        telegram: "",
        github: "",
        discord: "",
        instagram: "",
        reddit: "",
        mirror: "",
        email: "",
        youtube: "",
        other: "",
        audit_report: "", // 审计报告链接
      },
      fromFatsale: false,
    };
  },
  computed: {
    networks() {
      // 获取所有公链列表
      // 获取默认公链
      // 获取自定义公链
      // 合并返回
      const n = NETWORK_LIST;
      return n;
    },
    currentNetwork() {
      return this.$store.state.loginNetwork;
    },
    payAmount() {
      let amount = 300;
      console.log(this.form.chainId, "222");
      // if (parseInt(this.form.chainId) === CORE_MAINNET) {
      //   amount = 249;
      // }
      if (this.fromFatsale) {
        amount = 300;
        // if (parseInt(this.form.chainId) === CORE_MAINNET) {
        //   amount = 240;
        // }
      }
      return amount;
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    async submit() {
      if (this.submitLoading) {
        return;
      }
      // 验证
      if (!this.form.chainId) {
        this.$swal({
          title: "请选择主网",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.contract) {
        this.$swal({
          title: "请输入合约地址",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.uploadImg) {
        this.$swal({
          title: "请上传头像",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.email) {
        this.$swal({
          title: "请填写邮箱",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      // if (!this.form.hash) {
      //   if (this.payType === 0) {
      //     this.$swal({
      //       title: "请先进行付款",
      //       timer: 1200,
      //       timerProgressBar: true,
      //     });
      //     return;
      //   } else {
      //     this.$swal({
      //       title: "请填写转账哈希",
      //       timer: 1200,
      //       timerProgressBar: true,
      //     });
      //     return;
      //   }
      // }

      this.submitLoading = true;
      // 提交

      // const chains = this.networks.filter((item) => {
      //   return item.chainId === this.form.chainId;
      // });

      this.$swal({
        title: "请支付并等待交易完成,切勿关闭此页面",
        timer: 200000,
        timerProgressBar: true,
      });

      await this.pay();
      return;
      // if (!this.payHash) {
      //   this.submitLoading = true;
      //   return;
      // }

      // // 构建数据
      // const d = {
      //   ...this.form,
      //   chain: chains[0].shortname,
      //   logo: this.uploadImg,
      // };
      // try {
      //   // 请求接口
      //   const { data } = await submitTokenLogo(d);
      //   if (data.code === 200) {
      //     // 弹出提示
      //     this.$swal({
      //       icon: "success",
      //       title: "提交成功",
      //       text: "代币信息已提交, 有任何进度我们将通过邮件通知您",
      //       type: "success-message",
      //     });
      //   } else {
      //     this.$swal({
      //       title: "提交失败",
      //       text: data.message || "",
      //     });
      //   }
      // } catch (e) {
      //   this.$swal({
      //     title: "提交失败",
      //     text: e.reason || JSON.stringify(e),
      //   });
      // }
      // this.submitLoading = true;
    },
    changeContract(v) {
      this.form.contract = v;
      this.getTokenInfo(this.form.contract);
    },
    async getTokenInfo(tokenAddress) {
      // chainId
      const provider = getPrividerWithChainId(parseInt(this.form.chainId));
      // const factory = new ethers.Contract(
      //   tokenDeployFactoryMap[this.currentNetwork.chainId],
      //   tokenDeployFactoryAbi,
      //   provider
      // );
      // const a = await factory.tokenMap(tokenAddress);

      // if (parseInt(a.templateId.toString()) > 0) {
      //   this.fromFatsale = true;
      // } else {
      //   this.fromFatsale = false;
      // }
      this.fromFatsale = await getTokenFromFatsale(
        tokenAddress,
        this.form.chainId,
        provider
      );
      console.log("来自fatsale", this.fromFatsale);
    },
    async pay() {
      if (this.payLoading) {
        return;
      }
      // 判断是否连接钱包
      if (!this.currentNetwork.currentWallet) {
        this.$swal({
          title: "请先连接BSC钱包",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }
      if (this.currentNetwork.chainId !== BSC_MAINNET) {
        this.$swal({
          title: "请切换到BSC主网",
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      if (window.ethereum) {
        const chainId = await window.ethereum.request({
          method: "eth_chainId",
        });

        if (
          parseInt(BigNumber.from(chainId).toString()) !==
          this.currentNetwork.chainId
        ) {
          this.$swal({
            title: "请将钱包切换到BSC主网",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }
      }

      // 获取钱包余额
      const provider = toRaw(this.currentNetwork.provider);
      const token = new ethers.Contract(
        this.usdtAddress,
        tokenAbi,
        provider.getSigner()
      );
      const balance = await token.balanceOf(this.currentNetwork.currentWallet);
      console.log("余额", balance.toString() / 1e18);
      if (parseFloat(balance.toString() / 1e18) < parseFloat(this.payAmount)) {
        this.$swal({
          title: "余额不足",
          text: `钱包内USDT余额为 ${balance.toString() / 1e18} `,
          timer: 1200,
          timerProgressBar: true,
        });
        return;
      }

      this.payLoading = true;

      try {
        const x = await token.transfer(
          this.receivingWallet,
          ethers.utils.parseUnits(this.payAmount + "", 18)
        );

        // 提交到上面
        this.form.hash = x.hash;
        this.payHash = x.hash;

        const chains = this.networks.filter((item) => {
          return item.chainId === this.form.chainId;
        });
        const intro = this.form["intro"] + "\nQQ群: " + this.form["qq"];
        const d = {
          ...this.form,
          intro,
          chain: chains[0].shortname,
          logo: this.uploadImg,
        };
        // 请求接口
        submitTokenLogo(d);

        await x.wait();

        this.$swal({
          icon: "success",
          title: "提交成功",
          text: "代币信息已提交, 有任何进度我们将通过邮件通知您",
          type: "success-message",
        });
      } catch (e) {
        this.$swal({
          title: "支付失败",
          text: e.reason || JSON.stringify(e),
          timer: 3000,
          timerProgressBar: true,
        });
      }

      this.payLoading = false;
    },
    async copyAddress(address) {
      await this.$copyText(address);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    handleUploadProgress(file) {
      console.log(file);
    },

    handleUploadSuccess(file) {
      console.log(file);
    },
    async onSaveCropperImage() {
      const file = await cropper.getFile();
      console.log(file);
      let k = Date.now() + "" + file.name;
      const { width, height } = await getImageSize(file);

      this.showCropper = false;

      if (
        width > 200 ||
        height > 200 ||
        width / height > 1.2 ||
        width / height < 0.8
      ) {
        // // 请上传 90*90的头像
        // this.$swal({
        //   title: "LOGO大小不正确",
        //   text: "请上传 90*90 像素的图片",
        // });
        // return;
      }

      this.uploading = true;

      this.$nextTick(() => {
        AwsUpdate(file, k, (x) => {
          const percent = Math.round((x.loaded / x.total) * 10000) / 100;
          console.log("上传进度是", percent, "%");
          this.uploadPercent = percent;
        }).then((x) => {
          console.log("上传之后的结果是", x);
          this.uploadImg = x.Location;
          this.uploading = false;
        });
      });
    },
    onCancelCropperImage(file) {
      console.log("已取消", file);
    },
    async handleUpload(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onload = () => {
        const picValue = String(reader.result);
        this.cropperPic = picValue;
        // 展示裁剪框
        this.showCropper = true;
      };
      this.cropperImagePath = URL.createObjectURL(file.file);
      console.log(
        file,
        this.showCropper,
        this.cropperImagePath,
        AwsUpdate,
        getImageSize
      );

      // console.log(file);
      // let k = Date.now() + "" + file.file.name;
      // const { width, height } = await getImageSize(file.file);

      // if (
      //   width > 200 ||
      //   height > 200 ||
      //   width / height > 1.2 ||
      //   width / height < 0.8
      // ) {
      //   // 请上传 90*90的头像
      //   this.$swal({
      //     title: "LOGO大小不正确",
      //     text: "请上传 90*90 像素的图片",
      //   });
      //   return;
      // }

      // this.uploading = true;

      // this.$nextTick(() => {
      //   AwsUpdate(file.file, k, (x) => {
      //     const percent = Math.round((x.loaded / x.total) * 10000) / 100;
      //     console.log("上传进度是", percent, "%");
      //     this.uploadPercent = percent;
      //   }).then((x) => {
      //     console.log("上传之后的结果是", x);
      //     this.uploadImg = x.Location;
      //     this.uploading = false;
      //   });
      // });
    },
    handleSelectChain() {},
    changeTab(t) {
      this.currentTab = t;
      if (t === 1) {
        this.$nextTick(() => {
          console.log(document.getElementById("editor"), 222);
          if (document.getElementById("editor")) {
            new Quill("#editor", {
              theme: "snow", // Specify theme in configuration
            });
          }

          if (document.getElementById("choices-multiple-remove-button")) {
            var element = document.getElementById(
              "choices-multiple-remove-button"
            );
            console.log(element);
            const example = new Choices(element, {
              removeItemButton: true,
            });

            example.setChoices(
              [
                {
                  value: "One",
                  label: "Label One",
                  disabled: true,
                },
                {
                  value: "Two",
                  label: "Label Two",
                  selected: true,
                },
                {
                  value: "Three",
                  label: "Label Three",
                },
              ],
              "value",
              "label",
              false
            );
          }

          Dropzone.autoDiscover = false;
          var drop = document.getElementById("dropzone");
          new Dropzone(drop, {
            url: "/file/post",
            addRemoveLinks: true,
          });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.upload-img-ave {
  width: 100px;
  height: 100px;
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
  border: 1px dashed #8c939d;
}
.pay-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  font-weight: 800;
  color: rgb(155, 60, 60);
}
</style>
