import { ethers } from 'ethers'
const network = {
    state: {
        chainId: 0, // 当前的networId
        name: '',
        shortname: '',
        provider: null,
        logo: '',
        currentWallet: '',
        currency: '',
        privateKey: '',
        router: '', // 选择的交易所地址,
    },
    mutations: {
        SET_CURRENCY: (state, currency) => {
            state.currency = currency
        },
        SET_LOGO: (state, logo) => {
            state.logo = logo
        },
        SET_CHAIN_ID: (state, chainId) => {
            state.chainId = chainId
        },
        SET_NAME: (state, name) => {
            state.name = name
        },
        SET_SHORTNAME: (state, name) => {
            state.shortname = name
        },
        SET_PROVIDER: (state, provider) => {
            state.provider = provider
        },
        SET_CURRENT_WALLET: (state, w) => {
            state.currentWallet = w
        },
        SET_CURRENT_WALLET_PRIVATE_KEY: (state, w) => {
            state.privateKey = w
        },
        SET_ROUTER: (state, v) => {
            state.router = v
        },



    },
    actions: {
        SetCurrentNetwork({ commit }, network) {
            return new Promise((resolve) => {
                commit('SET_CHAIN_ID', network.chainId)
                commit('SET_NAME', network.name)
                commit('SET_SHORTNAME', network.shortname)
                commit('SET_LOGO', network.logo)
                commit('SET_CURRENCY', network.nativeCurrency.symbol)
                const nodes = network.rpcUrls
                const index = Math.floor((Math.random() * nodes.length))
                let provider = new ethers.providers.JsonRpcProvider(nodes[index])
                commit('SET_PROVIDER', provider)
                resolve()
            })
        },

    }
}
export default network