<template>
  <div class="container-fluid">
    <div
      class="mt-4 page-header min-height-200 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/img/curved-images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
      }"
    >
      <span class="mask bg-gradient-success3 opacity-6"></span>
    </div>

    <div class="mx-4 overflow-hidden card card-body blur shadow-blur mt-n6">
      <div class="row gx-4">
        <div class="col-auto">
          <div class="avatar avatar-xl position-relative">
            <img
              src="https://token-monitor.s3.amazonaws.com/1670952819043arnmi-93ua8.jpeg"
              alt="profile_image"
              class="shadow-sm w-100 border-radius-lg"
            />
          </div>
        </div>

        <div class="col-auto my-auto">
          <div class="h-100">
            <h5 class="mb-1">NFT批量转账工具</h5>
            <p class="mb-0 text-sm font-weight-bold">
              批量向地址中转入指定的NFT
            </p>
            <p class="mb-0 text-sm font-weight-bold">
              <!-- ✔ 批量合约已开源 支持任何审计 -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-4 container-fluid">
    <div class="row">
      <!--Allocations-->
      <!--列表-->
      <div class="col col-12 col-md-8 col-sm-12 mt-2">
        <div class="row">
          <div class="col col-12 col-md-4 col-sm-12">
            <div class="config-panel">
              <div class="config-panel-item">
                <!-- <div class="config-panel-item-header">转账方式</div> -->
                <label>转账方式</label>
                <div class="config-panel-item-body">
                  <select
                    id="choices-batch-transfer-type"
                    v-model="displayConfigPanel"
                    class="form-control"
                    @change="changeDisplayConfigPanelEnable()"
                  >
                    <option
                      v-for="item in [
                        {
                          name: '使用私钥',
                          typeId: true,
                        },
                        {
                          name: '使用当前连接的钱包',
                          typeId: false,
                        },
                      ]"
                      :key="item.typeId"
                      :value="item.typeId"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="config-panel-item">
                <label>转账TokenID</label>
                <div class="config-panel-item-body">
                  <select
                    id="choices-batch-transfer-type"
                    v-model="transferAmountType"
                    class="form-control"
                    @change="changeDisplayConfigPanelEnable()"
                  >
                    <option
                      v-for="item in [
                        {
                          name: '按序号自增',
                          typeId: 0,
                        },
                        {
                          name: '自定义ID',
                          typeId: 1,
                        },
                      ]"
                      :key="item.typeId"
                      :value="item.typeId"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="col col-12 col-md-8 col-sm-12">
            <ConfigPanel
              v-if="displayConfigPanel"
              :display-exchange="false"
              @change-wallet="syncApproveStatus(tokenAddress)"
              @change-network="syncApproveStatus(tokenAddress)"
            >
            </ConfigPanel>
          </div>
          <div class="col-12">
            <div class="config-panel">
              <div class="config-panel-item">
                <label>自定义RPC节点</label>
                <div class="config-panel-item-body">
                  <input
                    v-model="rpc"
                    type="text"
                    placeholder="http://"
                    class="form-control config-panel-text-item"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tool-panel mt-2">
          <div class="info-label-row">
            <label class="info-label-row">
              <span class="info-label-row">
                转账地址*
                <option-description
                  title="转账地址"
                  message="每行一个,如果使用连接的钱包转账, 则每次最多批量转账100个,超过100个请加载私钥进行多次转账,如果使用自增ID转账, 则仅需要填写要转账的地址, 如果使用自定义ID转账, 则需要在每行的地址后面添加转账TokenID, 以英文逗号分隔"
                >
                  <el-icon color="#999999">
                    <info-filled />
                  </el-icon>
                </option-description>
              </span>
            </label>
            <span class="info-label-row">
              <div>
                <el-upload :http-request="handleUpload" :show-file-list="false">
                  <template #trigger>
                    <el-button class="mini-btn" type="text"
                      >导入CSV文件</el-button
                    >
                  </template>
                </el-upload>
              </div>

              <a
                target="_blank"
                href="https://token-monitor.s3.amazonaws.com/1693879195183batch_send_NFTs_template.csv"
              >
                <el-button class="mini-btn" type="text">下载模版</el-button></a
              >
            </span>
          </div>

          <soft-textarea
            :value="Allocations"
            :scroll-to-top="true"
            :rows="31"
            :placeholder="textareaPlaceholder"
            @input-value="(v) => (Allocations = v)"
          />
        </div>
      </div>

      <!-- 输入代币合约 -->
      <!-- 选择转账为代币还是BNB -->
      <!--代币信息-->
      <!--操作开关-->
      <!--日志-->

      <div class="col col-12 col-md-4 col-sm-12 mt-2">
        <div class="tool-panel">
          <!-- <label class="config-panel-item-header">选择转账的币种</label>
          <div class="config-panel-item-body">
            <select
              id="choices-batch-transfer-type"
              v-model="isCurrencyETH"
              class="form-control"
              @change="changeDisplayConfigPanelEnable()"
            >
              <option
                v-for="item in [
                  {
                    name: `转账${currentNetwork.currency}`,
                    isCurrencyETH: true,
                  },
                  {
                    name: '转账合约代币',
                    isCurrencyETH: false,
                  },
                ]"
                :key="item.isCurrencyETH"
                :value="item.isCurrencyETH"
              >
                {{ item.name }}
              </option>
            </select>
          </div> -->
          <div v-if="!isCurrencyETH">
            <label class="form-label">输入NFT合约</label>
            <input
              v-model="tokenAddress"
              placeholder="0x..."
              type="text"
              class="form-control"
              @input="changeTokenAddress"
            />
          </div>
        </div>
        <div
          v-if="tokenInfo.totalSupply && !isCurrencyETH"
          class="tool-panel mt-2"
        >
          <label class="form-label">NFT信息</label>
          <ul class="mx-auto list-unstyled">
            <li class="d-flex mb-2">
              <p class="mb-0">Symbol:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.symbol
              }}</span>
            </li>

            <li class="d-flex">
              <p class="mb-0">Total Supply:</p>
              <span class="badge badge-secondary ms-auto">{{
                tokenInfo.totalSupply
              }}</span>
            </li>
          </ul>
          <soft-alert v-if="fromFatsale" color="info">
            <span style="font-size: 14px">
              该代币经由 <a href="https://fatsale.org/">Fatsale</a> 审计,
              可免费使用本工具
            </span>
          </soft-alert>
        </div>
        <div class="tool-panel mt-2">
          <div class="row">
            <!-- <div v-if="transferAmountType === 0" class="col-12 col-ms-12 mt-2">
              <div class="row">
                <div class="amount-count-btn">
                  <soft-button
                    color="info"
                    size="md"
                    full-width
                    variant="outline"
                    @click="oneKeySetAmount"
                    >设置转账数量</soft-button
                  >
                </div>
                <div v-if="parseFloat(tmpTransfer) > 0" class="amount-count">
                  {{ tmpTransfer }}
                  {{
                    isCurrencyETH ? currentNetwork.currency : tokenInfo.symbol
                  }}
                </div>
              </div>
            </div> -->
            <div class="col-12 col-ms-12 mt-2 mb-2">
              <div class="row">
                <div
                  v-if="!isApproved && !displayConfigPanel"
                  class="amount-count"
                >
                  <soft-button
                    color="warning"
                    size="md"
                    variant="contained"
                    full-width
                    @click="handleApprove"
                  >
                    <el-icon v-if="approveLoading" class="is-loading">
                      <loading />
                    </el-icon>
                    授权</soft-button
                  >
                </div>
                <div class="amount-count-btn">
                  <soft-button
                    color="info"
                    size="md"
                    variant="contained"
                    full-width
                    @click="batchTransferToken"
                  >
                    <el-icon v-if="submitLoading" class="is-loading">
                      <loading />
                    </el-icon>
                    开始转账</soft-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-dialog v-model="showOneKeySetAmountDialog" title="设置转账数量">
          <div>
            <form onsubmit="return false" class="text-start">
              <div class="mb-3">
                <!-- :value="logs" -->
                <soft-input
                  placeholder="输入向每个地址转账的数量"
                  @input-value="(v) => (tmpTransfer = v)"
                />
              </div>
              <div class="mb-3">
                <soft-button
                  class="my-4 mb-2"
                  variant="gradient"
                  color="info"
                  full-width
                  @click.stop="handleOneKeySetAmount"
                  >确定
                </soft-button>
              </div>
            </form>
          </div>
        </el-dialog>

        <div v-if="displayConfigPanel" class="log tool-panel mt-2">
          <label>日志</label>
          <soft-textarea
            :scroll-to-top="true"
            :value="logs"
            :rows="11"
            placeholder="交易日志"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ConfigPanel from "@/components/CommonConfig/ConfigPanel.vue";
import SoftTextarea from "@/components/SoftTextarea.vue";
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftInput from "@/components/SoftInput.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftRadio from "@/components/SoftRadio.vue";
import { WorkerPool } from "@/utils/worker_pool";
import { ElIcon, ElButton, ElUpload } from "element-plus";
import { CopyDocument, Loading, InfoFilled } from "@element-plus/icons-vue";

import { toRaw } from "vue";
import OptionDescription from "./components/OptionDescription.vue";
import {
  BATCH_TRANSFER_NFT,
  tokenAbi,
  nftAbi,
  initProvider,
  batchTransferNftAbi,
  chunkArray,
  readCsv,
  getTokenFromFatsale,
} from "@/utils/config";
import { Provider, Contract } from "ethers-multicall";
import { ethers } from "ethers";
// const PL_KEYS = "pl_keys";

export default {
  components: {
    ConfigPanel,
    CopyDocument,
    SoftTextarea,
    SoftSwitch,
    SoftInput,
    SoftButton,
    SoftAlert,
    ElIcon,
    Loading,
    SoftRadio,
    OptionDescription,
    InfoFilled,
    ElButton,
    ElUpload,
  },

  data() {
    return {
      rpc: "",
      isCurrencyETH: false,
      list: [],
      isApproved: false, // 判断是否已经授权
      showOneKeySetAmountDialog: false,
      tmpTransfer: "",
      logs: "",
      displayConfigPanel: true,
      Allocations: "",
      tokenAddress: "", // 代币合约
      transferAmountType: 0,
      tokenInfo: {
        name: "",
        symbol: "",
        decimals: "",
        totalSupply: "",
      },
      currentTab: 0,
      initLoading: false,
      prefixEnable: false,
      prefix: "",
      suffixEnable: true,
      submitLoading: false,
      approveLoading: false,
      suffix: "",
      middle: "",

      middleEnable: false,
      threadCount: "1",
      threadCountEnable: false,
      status: false, // 未开始
      diff: 0, // 难度
      totalCount: 0, // 已扫描地址数
      speed: "", // 扫描速度
      time: "", // 运行时间
      startTime: 0,
      init: false,
      searchRes: {
        public: "",
        privateKey: "",
      },
    };
  },
  computed: {
    // isApproved() {
    //   let t = false;

    //   if (
    //     this.tokenInfo.allowance &&
    //     parseInt(this.tokenInfo.allowance.toString()) >
    //       parseInt(maxApprove.toString()) * 0.9
    //   ) {
    //     t = true;
    //   }
    //   if (this.isCurrencyETH) {
    //     t = true;
    //   }
    //   if (this.displayConfigPanel) {
    //     // 用私钥, 不需要单独授权
    //     t = true;
    //   }

    //   return t;
    // },
    textareaPlaceholder() {
      if (this.transferAmountType === 0) {
        // 相同金额
        return `每行一个地址
示例: 

0x0000000000000000000000000000000000001000
0x0000000000000000000000000000000000001000
0x0000000000000000000000000000000000001000`;
      }
      return `每行一个, 格式为 地址+英文逗号+要转账的NFT ID
示例: address,NFT ID

0x0000000000000000000000000000000000001000,1
0x0000000000000000000000000000000000001000,2
0x0000000000000000000000000000000000001000,3`;
    },
    currencyName() {
      return this.currentNetwork.currency;
    },

    currentNetwork() {
      // return this.$store.state.loginNetwork;
      if (this.displayConfigPanel) {
        //用的是私钥
        let n = this.$store.state.network;
        // 如果自定义了rpc, 用自定义的
        return n;
      } else {
        //用的是小狐狸
        return this.$store.state.loginNetwork;
      }
    },

    premiumDiff() {
      // 扫描难度
      let t = 0;
      if (this.prefixEnable) {
        t += this.prefix.length || 0;
      }
      if (this.suffixEnable) {
        t += this.suffix.length || 0;
      }
      if (this.middleEnable) {
        t += this.middle.length || 0;
      }
      return Math.pow(16, t);
    },
  },
  mounted() {
    // const list = localStorage.getItem(PL_KEYS);
    // if (list) {
    //   this.list = JSON.parse(list);
    //   this.updateAllocations()
    // }

    setTimeout(() => {
      // this.changeInput();
      // this.refreshAmount();
      this.changeTokenAddress();
    }, 1000);
  },
  methods: {
    async handleUpload(file) {
      console.log(file);
      const a = await readCsv(file.file);
      console.log(a, "aaa");
      const arr = a.split("\n");
      if (!arr || !arr.length) {
        this.$swal({
          title: "导入失败",
        });
        return;
      }
      let rows = [];
      for (let i = 0; i < arr.length; i++) {
        const arr2 = arr[i].split(",");
        if (arr2.length < 2) continue;
        if (this.isAddress(arr2[0]) && this.isNumber(arr2[1]) !== false) {
          // 是地址, 并且第二个参数是数字
          rows.push(
            `${arr2[0].replaceAll("\r", "").replaceAll("\t", "")},${arr2[1]
              .replaceAll("\r", "")
              .replaceAll("\t", "")}`
          );
        }
      }
      this.transferAmountType = 1;
      this.Allocations = rows.join("\n");
      this.$swal({
        title: "导入完成",
        text: `已导入 ${rows.length} 条数据`,
      });
    },
    isNumber(n) {
      try {
        return parseFloat(n);
      } catch (e) {
        return false;
      }
    },
    isAddress(address) {
      return address.toLowerCase().indexOf("0x") === 0 && address.length === 42;
    },
    getProvider() {
      if (this.rpc && this.displayConfigPanel) {
        console.log("使用rpc", this.rpc);
        return new ethers.providers.JsonRpcProvider(this.rpc);
      } else {
        return toRaw(this.currentNetwork.provider);
      }
    },
    async handleApprove() {
      if (this.approveLoading) return;
      const p = this.getProvider();
      const batchTransferAddress =
        BATCH_TRANSFER_NFT[this.currentNetwork.chainId];
      //授权数量不达标 继续授权
      try {
        this.approveLoading = true;
        const c = new ethers.Contract(this.tokenAddress, nftAbi, p.getSigner());

        const x = await c.setApprovalForAll(batchTransferAddress, true);

        await x.wait();
        this.$swal({
          title: "授权成功",
        });
        this.syncApproveStatus(this.tokenAddress);
      } catch (e) {
        this.log(e.reason || JSON.stringify(e)); //
      }
      this.approveLoading = false;
    },
    async handleOneKeySetAmount() {
      var regexAmount = /^\d+(\.\d+)?$/; //匹配是否为小数或者整数
      var isMatch = regexAmount.test(this.tmpTransfer);
      if (!isMatch) {
        await this.$swal({
          title: "数值格式错误",
          text: "请输入一个整数或者小数",
          confirmButtonText: "确认",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.showOneKeySetAmountDialog = false;
    },
    oneKeySetAmount() {
      this.showOneKeySetAmountDialog = true;
    },
    async refreshAmount() {
      try {
        if (!this.tokenInfo.totalSupply) {
          this.changeTokenAddress();
        }
        // 同步数量
        const provider = this.getProvider();
        let ethcallProvider = new Provider(provider);
        ethcallProvider = await initProvider(
          ethcallProvider,
          this.currentNetwork.chainId
        );
        if (this.list && this.list.length > 0) {
          const token = new Contract(this.tokenAddress, tokenAbi);
          let call = [];
          for (let i = 0; i < this.list.length; i++) {
            const item = this.list[i];
            call.push(ethcallProvider.getEthBalance(item.publicKey));
            call.push(token.balanceOf(item.publicKey));
          }

          const res = await ethcallProvider.all(call);
          this.list.forEach((item, ind) => {
            const r1 = res[ind * 2 + 0];
            const r2 = res[ind * 2 + 1];
            this.list[ind].balance = r1.toString() / 1e18;
            this.list[ind].tokenBalance =
              r2.toString() / Math.pow(10, this.tokenInfo.decimals);
          });
        }
      } catch (e) {
        // console.log(e);
      }

      this.refreshTimer = setTimeout(() => {
        this.refreshAmount();
      }, 5000);
    },
    log(str) {
      this.logs += "\n" + str;
      //   let logE = this.$refs["logInput"];
      //   logE.scrollTop(logE.scrollHeight - logE.height());
      this.$nextTick(() => {
        console.log(this.logs);
        // console.log(
        //   this.$refs["logInput"].$refs.textarea.scrollTop,
        //   this.$refs["logInput"].$refs.textarea.scrollHeight,
        //   this.$refs["logInput"].$refs.textarea
        // );
        // this.$refs["logInput"].$refs.textarea.scrollTop =
        // this.$refs["logInput"].$refs.textarea.scrollHeight;
      });
    },
    async getTokenBalance(wallet) {
      // 根据地址获取
      const provider = this.getProvider();
      let ethcallProvider = new Provider(toRaw(provider));
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(this.tokenAddress, tokenAbi);
      const calls = [
        ethcallProvider.getEthBalance(wallet),
        token.balanceOf(wallet),
      ];
      const res = await ethcallProvider.all(calls);
      console.log(res);
      return {
        bnbBalance: res[0].toString() / 1e18,
        tokenBalance: res[1].toString() / Math.pow(10, this.tokenInfo.decimals),
      };
    },
    // changeAllocations(){
    //   this.updateAllocations()
    // },
    async batchTransferToken() {
      if (this.submitLoading) return;
      console.log(this.currentNetwork.currentWallet);
      try {
        if (!this.currentNetwork.currentWallet) {
          this.$swal({
            title: "请加载转账钱包",
            text: tampT,
            confirmButtonText: "确认",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
          return;
        }

        if (!this.displayConfigPanel && !this.isApproved) {
          this.$swal({
            title: "请先进行授权",
            confirmButtonText: "确认",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          });
          return;
        }

        const batchTransferAddress =
          BATCH_TRANSFER_NFT[this.currentNetwork.chainId];

        if (!batchTransferAddress) {
          this.$swal({
            title: "暂不支持当前公链",
            timer: 1200,
            timerProgressBar: true,
          });
          return;
        }

        if (this.rpc) {
          this.log("当前使用节点:" + this.rpc);
          if (this.rpc.indexOf("http") === -1) {
            this.$swal({
              title: "请输入正确的节点格式",
              timer: 1200,
              timerProgressBar: true,
            });
            return;
          }
        }

        this.submitLoading = true;

        if (!this.isCurrencyETH) {
          if (!this.tokenInfo.symbol) {
            await this.getTokenInfo(this.tokenAddress);
          }

          if (!this.tokenInfo.symbol) {
            this.$swal({
              title: "未找到代币信息",
              confirmButtonText: "确认",
              reverseButtons: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
            this.submitLoading = false;
            return;
          }
        }

        var addressAndAmount = this.Allocations.split("\n");
        // var regexAddress = new RegExp("(0x[a-zA-Z0-9]{40}),([0-9]+([.0-9]+)?)");
        const addrL = [];
        const amountL = [];
        var temp;
        var tampAmount;
        let myNftIdList = [];
        if (this.transferAmountType === 0) {
          // 获取我持有的nft的id列表, 按照顺序分配给其他人
          const provider = this.getProvider();
          const n = new ethers.Contract(this.tokenAddress, nftAbi, provider);
          const myNftIds = await n.tokensOfOwner(
            this.currentNetwork.currentWallet
          );
          myNftIdList = myNftIds.map((item) => parseInt(item.toString()));
          if (myNftIdList.length < addressAndAmount.length) {
            this.$swal({
              title: "持有的NFT数量不足",
              text: `持有 ${myNftIdList.length} 个NFT, 需要向 ${addressAndAmount.length}个地址进行转账, 请修改后重试`,
              confirmButtonText: "确认",
              reverseButtons: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
            this.submitLoading = false;
            return;
          }
        }

        for (var i = 0; i < addressAndAmount.length; i++) {
          // temp = regexAddress.exec(addressAndAmount[i]);
          console.log(addressAndAmount[i]);
          // console.log(temp);
          const tmpRow = addressAndAmount[i]
            .replaceAll("\r", "")
            .replaceAll("\t", "")
            .replaceAll("\n", "")
            .replaceAll(" ", "");
          if (!tmpRow) continue;

          temp = tmpRow.split(",");

          if (this.transferAmountType === 0) {
            temp[1] = myNftIdList[i];
          }

          if (!temp) {
            var tampT;
            if (!addressAndAmount[i]) {
              tampT = "请删除空白行后重试";
            } else {
              tampT = `第 ${i + 1} 行地址 ${addressAndAmount[i]} 格式错误`;
            }

            await this.$swal({
              title: "请检查格式",
              text: tampT,
              confirmButtonText: "确认",
              reverseButtons: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
            this.submitLoading = false;
            return;
          }
          //格式检查无误 进行储存
          addrL.push(temp[0]);
          if (!this.isCurrencyETH) {
            tampAmount = ethers.utils.parseUnits(temp[1] + "", 0);
            amountL.push(tampAmount);
          } else {
            tampAmount = ethers.utils.parseUnits(temp[1] + "", 0);
            amountL.push(tampAmount);
          }
        }
        //检查两个数组长度是否相同
        if (addrL.length != amountL.length) {
          await this.$swal({
            title: "",
            text: "请检查格式！",
            confirmButtonText: "确认",
            reverseButtons: true,
            customClass: {
              // cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          });
          this.submitLoading = false;
          return;
        }

        const BATCH_COUNT = 50;

        if (!this.displayConfigPanel && addrL.length > BATCH_COUNT) {
          this.$swal({
            title: "地址过多",
            text: `使用钱包调用方式, 单次空投地址数不能超过${BATCH_COUNT}个, 请减少后重试`,
            confirmButtonText: "确认",
            reverseButtons: true,
            customClass: {
              // cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          });
          this.submitLoading = false;
          return;
        }

        // 如果是私钥, 弹一个确认框
        if (this.displayConfigPanel) {
          const rr = await this.$swal({
            title: "确认?",
            text: `即将使用私钥进行转账`,
            showCancelButton: true,
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          });
          if (!rr.isConfirmed) {
            this.submitLoading = false;
            return;
          }
        }

        const addrChunk = chunkArray(addrL, BATCH_COUNT);
        const amountChunk = chunkArray(amountL, BATCH_COUNT);

        this.log("开始转账...");
        for (let i = 0; i < addrChunk.length; i++) {
          const dChunks = addrChunk[i];
          const aChunks = amountChunk[i];

          console.log("dddddlist", dChunks, aChunks);
          this.log(`正在进行第 ${i + 1} 次转账, 转账地址 ${dChunks.length} 个`);

          if (this.displayConfigPanel) {
            //私钥
            //检查钱包余额
            const p = this.getProvider();
            const w = new ethers.Wallet(this.currentNetwork.privateKey, p);
            /////////////////////////////////////////////////////
            // for (let i = 0; i < this.list.length; i++) {
            // const wallet = this.list[i];

            //转账的是代币 要授权
            if (!this.isCurrencyETH) {
              //判断是否授权
              const token = new ethers.Contract(this.tokenAddress, nftAbi, p);
              const ap = await token.isApprovedForAll(
                w.address,
                batchTransferAddress
              );
              if (!ap) {
                //授权数量不达标 继续授权
                try {
                  let tFace = new ethers.utils.Interface(nftAbi);
                  console.log(batchTransferAddress);
                  const dd = tFace.encodeFunctionData("setApprovalForAll", [
                    batchTransferAddress,
                    true,
                  ]);
                  console.log(dd);

                  const tx = {
                    from: w.address,
                    to: this.tokenAddress,
                    // gasLimit: ethers.utils.parseUnits("22000", 0), //this.gasLimit
                    data: dd,
                    value: ethers.utils.parseEther("0"),
                  };
                  const x = await w.sendTransaction(tx);
                  this.log(
                    `钱包 ${w.address} 向 ${this.tokenInfo.symbol} (${this.tokenAddress}) 中给 转账合约${batchTransferAddress} 授权, 授权哈希: ${x.hash}`
                  );
                  await x.wait();
                  this.log(`${x.hash} 授权成功！`);
                } catch (e) {
                  this.log(e.reason || JSON.stringify(e)); //
                }
              }
            }

            //授权完成 进行转账
            let bFace = new ethers.utils.Interface(batchTransferNftAbi);

            const dd = bFace.encodeFunctionData("MultiTransferNFT", [
              this.tokenAddress,
              dChunks,
              aChunks,
            ]);
            console.log(this.tokenAddress, dChunks, aChunks, "xxxx");
            var approveTx;
            approveTx = {
              from: w.address,
              to: batchTransferAddress,
              data: dd,
            };

            console.log(approveTx);
            const ax = await w.sendTransaction(approveTx);
            this.log(`交易已提交, hash: ${ax.hash} 等待交易完成...`);
            ax.wait()
              .then(() => {
                this.log(`批量交易 ${ax.hash} 已完成`);
              })
              .catch((e) => {
                let r = JSON.stringify(e);
                if (e.reason) {
                  r = e.reason;
                }
                this.log(r);
              });
          } else {
            //小狐狸
            //检查钱包余额
            const p = this.getProvider();

            /////////////////////////////////////////////////////
            // for (let i = 0; i < this.list.length; i++) {
            // const wallet = this.list[i];
            const batchTransferAddress =
              BATCH_TRANSFER_NFT[this.currentNetwork.chainId];
            //转账的是代币 需要授权
            if (!this.isCurrencyETH) {
              //判断是否授权
              const token = new ethers.Contract(this.tokenAddress, nftAbi, p);
              const ap = await token.isApprovedForAll(
                this.currentNetwork.currentWallet,
                batchTransferAddress
              );
              if (!ap) {
                //授权数量不达标 继续授权
                try {
                  // const amount = b.toString() / dec;
                  const c = new ethers.Contract(
                    this.tokenAddress,
                    nftAbi,
                    p.getSigner()
                  );

                  const x = await c.setApprovalForAll(
                    batchTransferAddress,
                    true
                  );
                  this.log(
                    `钱包 ${this.currentNetwork.currentWallet} 向 ${this.tokenInfo.symbol} (${this.tokenAddress}) 中给 转账合约${batchTransferAddress} 授权, 授权哈希: ${x.hash}`
                  );
                  x.wait()
                    .then(() => {
                      this.log(`${x.hash} 授权成功！`);
                    })
                    .catch((e) => {
                      let r = JSON.stringify(e);
                      if (e.reason) r = e.reason;
                      this.log(r);
                    });
                } catch (e) {
                  this.log(e.reason || JSON.stringify(e)); //
                }
              }
            }

            //授权完成 进行转账
            //构造批量转账合约
            const bt = new ethers.Contract(
              batchTransferAddress,
              batchTransferNftAbi,
              p.getSigner()
            );

            //发送交易
            var ax;
            //代币
            ax = await bt.MultiTransferNFT(this.tokenAddress, dChunks, aChunks);

            this.log(`交易已提交, hash: ${ax.hash} 等待交易完成...`);
            ax.wait()
              .then(() => {
                this.$swal({
                  title: "",
                  text: `交易 ${ax.hash} 已完成`,
                  // showCancelButton: true,
                  confirmButtonText: "确认",
                  // cancelButtonText: "取消",
                  reverseButtons: true,
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                    // cancelButton: "btn bg-gradient-danger",
                  },
                  buttonsStyling: false,
                });
                // return;
                this.log(`小狐狸批量交易 ${ax.hash} 已完成`);
              })
              .catch((e) => {
                let r = JSON.stringify(e);
                if (e.reason) {
                  r = e.reason;
                }
                this.log(r);
              });
          }
        }
      } catch (error) {
        if (!this.displayConfigPanel) {
          // 用钱包
          this.$swal({
            title: "转账失败",
            text: error.reason || JSON.stringify(error),
          });
        }
        console.log(error);
        this.log(error.reason || JSON.stringify(error));
      }

      this.submitLoading = false;
    },

    async exportWalletPublic() {
      const a = this.list
        .map((item) => {
          return item.publicKey;
        })
        .join("\n");
      await this.$copyText(a);
      this.$swal({
        title: "已复制所有地址到剪贴板",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async exportWallets() {
      const a = this.list
        .map((item) => {
          return item.privateKey;
        })
        .join("\n");
      await this.$copyText(a);
      this.$swal({
        title: "已复制所有私钥到剪贴板",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    async copyAddress(address) {
      console.log(address);
      await this.$copyText(address);
      this.$swal({
        title: "已复制",
        timer: 1200,
        timerProgressBar: true,
      });
    },
    getShortAddr(address) {
      if (address.length >= 42) {
        return address.slice(0, 4) + "..." + address.slice(address.length - 5);
      }
      return address;
    },
    async changeTokenAddress() {
      if (!this.tokenAddress || this.tokenAddress.length !== 42) {
        this.tokenInfo = {
          name: "",
          symbol: "",
          decimals: "",
          totalSupply: "",
        };
        return;
      }

      this.getTokenInfo(this.tokenAddress);
    },
    async getTokenInfo(tokenAddress) {
      const provider = this.getProvider();
      let ethcallProvider = new Provider(provider);
      ethcallProvider = await initProvider(
        ethcallProvider,
        this.currentNetwork.chainId
      );

      const token = new Contract(tokenAddress, nftAbi);
      console.log(BATCH_TRANSFER_NFT);
      //   const batchTransferAddress =
      //     BATCH_TRANSFER_NFT[this.currentNetwork.chainId];

      //   try {
      const calls = [
        token.name(),
        token.symbol(),
        token.totalSupply(),
        // token.isApprovedForAll(
        //   this.currentNetwork.currentWallet,
        //   batchTransferAddress
        // ),
      ];
      console.log(ethcallProvider, calls, this.currentNetwork.currentWallet);
      const res = await ethcallProvider.all(calls);
      console.log(res);
      this.tokenInfo = {
        name: res[0],
        symbol: res[1],
        totalSupply: res[2].toString(),
        // allowance: res[4],
      };
      this.syncApproveStatus(tokenAddress);
      // const factory = new ethers.Contract(
      //   tokenDeployFactoryMap[this.currentNetwork.chainId],
      //   tokenDeployFactoryAbi,
      //   provider
      // );
      // const a = await factory.tokenMap(tokenAddress);
      // if (parseInt(a.templateId.toString()) > 0) {
      //   this.fromFatsale = true;
      // } else {
      //   this.fromFatsale = false;
      // }
      this.fromFatsale = await getTokenFromFatsale(
        tokenAddress,
        this.currentNetwork.chainId,
        provider
      );
      //   } catch (e) {
      //     console.log(e);
      //   }
    },
    async syncApproveStatus(tokenAddress) {
      setTimeout(async () => {
        if (this.currentNetwork.currentWallet) {
          const provider = this.getProvider();
          console.log("aaaaa", tokenAddress);
          const token = new ethers.Contract(tokenAddress, nftAbi, provider);
          const batchTransferAddress =
            BATCH_TRANSFER_NFT[this.currentNetwork.chainId];
          const ap = await token.isApprovedForAll(
            this.currentNetwork.currentWallet,
            batchTransferAddress
          );
          console.log(ap, "aaaaa");
          this.isApproved = ap;
        }
      }, 300);
    },
    async connectWallet() {
      if (!window.ethereum) {
        this.$swal({
          title: "请安装Metamask插件",
          timer: 2000,
          timerProgressBar: true,
        });
        return;
      }

      this.$store.dispatch("SetLoginWallet");
    },
    changeDisplayConfigPanelEnable() {
      console.log("this.displayConfigPanel = " + this.displayConfigPanel);
      if (this.displayConfigPanel) {
        // 显示了 说明用的是私钥形式
      } else {
        // 不显示 说明用的是小狐狸形式

        if (!this.currentNetwork.currentWallet) {
          this.connectWallet();
        }
      }
    },
    // async createWallets() {
    //   // 批量生成钱包数
    //   console.log("该弹窗了")
    //   let text = "";
    //   if (this.list.length > 0) {
    //     text = "生成后将覆盖现有钱包, 请确保已导出保存当前已生成的私钥或已清空转账钱包";
    //   }
    //   const r = await this.$swal({
    //     title: "输入要生成的钱包数量",
    //     text,
    //     input: "text",
    //     inputAttributes: {
    //       autocapitalize: "off",
    //     },
    //     showCancelButton: true,
    //     showLoaderOnConfirm: true,
    //     buttonsStyling: false,
    //     customClass: {
    //       confirmButton: "btn bg-gradient-success",
    //       cancelButton: "btn bg-gradient-danger",
    //     },
    //   });
    //   console.log(r);
    //   if (!r.isConfirmed) return;
    //   const c = r.value;

    //   // const r = await this.$prompt("输入要生成的钱包数量");
    //   // if (r.action !== "confirm") return;
    //   // const c = r.value;
    //   let pks = []; // 公钥
    //   let prks = []; // 私钥

    //   for (let i = 0; i < parseInt(c); i++) {
    //     const wallet = ethers.Wallet.createRandom();
    //     console.log(wallet);
    //     const publicKey = wallet.address;
    //     const privateKey = wallet._signingKey().privateKey;

    //     pks.push(publicKey);
    //     prks.push(privateKey);
    //     // 添加数据
    //     this.list.push({
    //       publicKey,
    //       privateKey,
    //       balance: 0,
    //       tokenBalance: 0,
    //     });
    //     await this.$forceUpdate();
    //   }
    //   this.privateKeys = prks.join("\n");
    //   this.publicKeys = pks.join("\n");
    //   localStorage.setItem(PL_KEYS, JSON.stringify(this.list));
    //   this.updateAllocations()
    // },
    async changeThreadEnable(v) {
      console.log(v);
      v = !v;
      if (v) {
        // 验证是否是vip
        // 如果不是vip
        // 弹出验证框, 如果是vip, 请切换到bsc主网办理
        // console.log(this.$store.state.loginNetwork.vipStatus);
        if (this.$store.state.loginNetwork.vipInfo.length === 0) {
          const r = await this.$swal({
            title: "请开通VIP",
            text: `当前功能为付费服务, 请先开通VIP, 即可解锁全部性能, 生成速度指数增长`,
            showCancelButton: true,
            confirmButtonText: "去开通",
            cancelButtonText: "取消",
            reverseButtons: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            buttonsStyling: false,
          });
          if (!r.isConfirmed) {
            return;
          } else {
            // 跳转到开通vip的界面
            this.$router.push({
              path: "/vip",
            });
            return;
          }
        }
      }
      this.threadCountEnable = v;
    },
    changeTab(t) {
      this.currentTab = t;
    },
    startContractEoa() {
      // 开始
      this.status = true;
      this.totalCount = "正在初始化运行环境";

      // 开启定时器定时刷新total
      this.startPremiumContractEoa();
    },
    async startPremiumContractEoa() {
      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      console.log("这是初始化完成的回调");
      this.totalCount = "";
      this.startTime = Date.parse(new Date()) / 1000;
      console.log(window.goWorkers);
      // 调用生成
      window.goWorkers.ContractEoaGenerator(
        [
          this.prefixEnable ? this.prefix : "",
          this.suffixEnable ? this.suffix : "",
          this.middleEnable ? this.middle : "",
        ],
        (total) => {
          //   console.log("一共生产了", total);
          this.totalCount = total;
          const nowT = Date.parse(new Date()) / 1000;
          this.speed = parseInt(
            parseInt(this.totalCount) / (nowT - this.startTime)
          );
          this.time = nowT - this.startTime;
        },
        (res) => {
          console.log("找到了", res);
          const r = res.split(",");
          if (r.length === 3) {
            this.searchRes = {
              public: r[0],
              privateKey: r[1],
              contract: r[2],
            };

            this.$swal({
              icon: "success",
              title: "生成成功",
              html:
                `<b>钱包地址</b>: ${this.searchRes.public} <br /> ` +
                `<b>私钥</b>: ${this.searchRes.privateKey} <br /> ` +
                `<b>首次部署的合约地址</b>: ${
                  this.searchRes.contract || ""
                } <br /> `,
              type: "success-message",
            });

            this.stopPremiumContract();
          }
        }
      );
    },
    start() {
      // 开始
      this.status = true;
      this.totalCount = "正在初始化运行环境";

      // 开启定时器定时刷新total
      this.startPremiumContract();
    },
    async stopPremiumContract() {
      window.goWorkers && window.goWorkers.stop();
      this.status = false;
      this.init = false;
      window.goWorkers = undefined;
    },
    initEnv() {
      return new Promise((resolve) => {
        this.initLoading = true;
        // 初始化环境
        window.goWorkers = new WorkerPool(
          "./worker5.js",
          parseInt(this.threadCount)
        );
        window.goWorkers.init(
          // "./test.wasm",
          // 'https://token-monitor.s3.amazonaws.com/test_12_02_01_52.wasm',
          "https://token-monitor.s3.amazonaws.com/test_01_05_10_48.wasm",
          (nowLength, totalLength) => {
            const p = ((nowLength / totalLength) * 100).toFixed(2);
            console.log("这是初始化过程中的回调", p);
            this.totalCount = "正在初始化运行环境" + `(${p}%)`;
          },
          () => {
            this.init = true;
            this.initLoading = false;
            resolve();
          }
        );
      });
    },
    async startPremiumContract() {
      if (!this.init || !window.goWorkers) {
        await this.initEnv();
      }
      console.log("这是初始化完成的回调");
      this.totalCount = "";
      this.startTime = Date.parse(new Date()) / 1000;
      console.log(window.goWorkers);
      // 调用生成
      window.goWorkers.WalletGenerator(
        [
          this.prefixEnable ? this.prefix : "",
          this.suffixEnable ? this.suffix : "",
          this.middleEnable ? this.middle : "",
        ],
        (total) => {
          //   console.log("一共生产了", total);
          this.totalCount = total;
          const nowT = Date.parse(new Date()) / 1000;
          this.speed = parseInt(
            parseInt(this.totalCount) / (nowT - this.startTime)
          );
          this.time = nowT - this.startTime;
        },
        (res) => {
          console.log("找到了", res);
          const r = res.split(",");
          if (r.length === 2) {
            this.searchRes = {
              public: r[0],
              privateKey: r[1],
            };

            this.$swal({
              icon: "success",
              title: "生成成功",
              html:
                `<b>地址</b>: ${this.searchRes.public} <br /> ` +
                `<b>私钥</b>: ${this.searchRes.privateKey} <br /> `,
              type: "success-message",
            });

            this.stopPremiumContract();
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gradient-success3 {
  background-image: linear-gradient(310deg, #93e6a5 0%, #a391c8 100%);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #344767 !important;
  background-color: #fff !important;
}
.tool-panel {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}

.config-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  &-item {
    flex: 1;
    margin-right: 20px;
    &-body {
      margin-top: 8px;
    }
    &-header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.panel {
  width: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
}
.info-label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.mini-btn {
  font-size: 12px;
  &-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
  }
}
.amount-count-btn {
  flex: 2;
}
.amount-count {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: flex-end;
  justify-content: center;
  font-weight: 600;
}
</style>